import tbl1 from "../../asset/images/office furniture/tbl1.jpg";
import tbl2 from "../../asset/images/office furniture/tbl2.jpg";
import tbl3 from "../../asset/images/office furniture/tbl3.jpg";
import tbl4 from "../../asset/images/office furniture/tbl4.jpg";
import tbl5 from "../../asset/images/office furniture/tbl5.jpg";
import tbl6 from "../../asset/images/office furniture/tbl6.jpg";
import tbl7 from "../../asset/images/office furniture/tbl7.jpg";
import tbl8 from "../../asset/images/office furniture/tbl8.jpg";
import tbl9 from "../../asset/images/office furniture/tbl9.jpg";
import tbl10 from "../../asset/images/office furniture/tbl10.jpg";
import tbl11 from "../../asset/images/office furniture/tbl11.jpg";
import tbl12 from "../../asset/images/office furniture/tbl12.jpg";
import tbl13 from "../../asset/images/office furniture/tbl13.jpg";
import tbl14 from "../../asset/images/office furniture/tbl14.jpg";
import tbl15 from "../../asset/images/office furniture/tbl15.jpg";
import tbl16 from "../../asset/images/office furniture/tbl16.jpg";
import tbl17 from "../../asset/images/office furniture/tbl17.jpg";
import tbl18 from "../../asset/images/office furniture/tbl18.jpg";
import tbl19 from "../../asset/images/office furniture/tbl19.jpg";
import tbl20 from "../../asset/images/office furniture/tbl20.jpg";
import tbl21 from "../../asset/images/office furniture/tbl21.jpg";
import tbl22 from "../../asset/images/office furniture/tbl22.jpg";
import tbl23 from "../../asset/images/office furniture/tbl23.jpg";
import tbl24 from "../../asset/images/office furniture/tbl24.jpg";
import tbl25 from "../../asset/images/office furniture/tbl25.jpg";
import tbl26 from "../../asset/images/office furniture/tbl26.jpg";
import tbl27 from "../../asset/images/office furniture/tbl27.jpg";
import tbl28 from "../../asset/images/office furniture/tbl28.jpg";
import tbl29 from "../../asset/images/office furniture/tbl29.jpg";
import tbl30 from "../../asset/images/office furniture/tbl30.jpg";
import tbl31 from "../../asset/images/office furniture/tbl31.jpg";
import tbl32 from "../../asset/images/office furniture/tbl32.jpg";
import tbl33 from "../../asset/images/office furniture/tbl33.jpg";
import tbl34 from "../../asset/images/office furniture/tbl34.jpg";
import tbl35 from "../../asset/images/office furniture/tbl35.jpg";
import tbl36 from "../../asset/images/office furniture/tbl36.jpg";
import tbl37 from "../../asset/images/office furniture/tbl37.jpg";
import tbl38 from "../../asset/images/office furniture/tbl38.jpg";
import tbl39 from "../../asset/images/office furniture/tbl39.jpg";
import tbl40 from "../../asset/images/office furniture/tbl40.jpg";
import tbl41 from "../../asset/images/office furniture/tbl41.jpg";
import tbl42 from "../../asset/images/office furniture/tbl42.jpg";
import tbl43 from "../../asset/images/office furniture/tbl43.jpg";
import tbl44 from "../../asset/images/office furniture/tbl44.jpg";
import tbl45 from "../../asset/images/office furniture/tbl45.jpg";
import tbl46 from "../../asset/images/office furniture/tbl46.jpg";
import tbl47 from "../../asset/images/office furniture/tbl47.jpg";
import tbl48 from "../../asset/images/office furniture/tbl48.jpg";
import tbl49 from "../../asset/images/office furniture/tbl49.jpg"
import tbl50 from "../../asset/images/office furniture/tbl50.jpg";
import tbl51 from "../../asset/images/office furniture/tbl51.jpg";
import tbl52 from "../../asset/images/office furniture/tbl52.jpg";
import tbl53 from "../../asset/images/office furniture/tbl53.jpg";
import tbl54 from "../../asset/images/office furniture/tbl54.jpg";
import tbl55 from "../../asset/images/office furniture/tbl55.jpg";
import tbl56 from "../../asset/images/office furniture/tbl56.jpg";
import tbl57 from "../../asset/images/office furniture/tbl57.jpg";
import tbl58 from "../../asset/images/office furniture/tbl58.jpg";
import tbl59 from "../../asset/images/office furniture/tbl59.jpg";
import tbl60 from "../../asset/images/office furniture/tbl60.jpg";
import tbl61 from "../../asset/images/office furniture/tbl61.jpg";
import tbl62 from "../../asset/images/office furniture/tbl62.jpg";
import tbl63 from "../../asset/images/office furniture/tbl63.jpg";
import tbl64 from "../../asset/images/office furniture/tbl64.jpg";
import tbl65 from "../../asset/images/office furniture/tbl65.jpg";
import tbl66 from "../../asset/images/office furniture/tbl66.jpg";
import tbl67 from "../../asset/images/office furniture/tbl67.jpg";
import tbl68 from "../../asset/images/office furniture/tbl68.jpg";
import tbl69 from "../../asset/images/office furniture/tbl69.jpg";
import tbl70 from "../../asset/images/office furniture/tbl70.jpg";
import tbl71 from "../../asset/images/office furniture/tbl71.jpg";
import tbl72 from "../../asset/images/office furniture/tbl72.jpg";
import tbl73 from "../../asset/images/office furniture/tbl73.jpg";
import tbl74 from "../../asset/images/office furniture/tbl74.jpg";
import tbl75 from "../../asset/images/office furniture/tbl75.jpg";
import tbl76 from "../../asset/images/office furniture/tbl76.jpg";
import tbl77 from "../../asset/images/office furniture/tbl77.jpg";
import tbl78 from "../../asset/images/office furniture/tbl78.jpg";
import tbl79 from "../../asset/images/office furniture/tbl79.jpg";
import tbl80 from "../../asset/images/office furniture/tbl80.jpg";

//                File Cabinet
import fileCabinet1 from "../../asset/images/office furniture/file cabinet/1.jpg";
import fileCabinet2 from "../../asset/images/office furniture/file cabinet/2.jpg";
import fileCabinet3 from "../../asset/images/office furniture/file cabinet/3.jpg";
// import fileCabinet4 from "../../asset/images/office furniture/file cabinet/4.jpg";
import fileCabinet5 from "../../asset/images/office furniture/file cabinet/5.jpg";
import fileCabinet6 from "../../asset/images/office furniture/file cabinet/6.jpg";
import fileCabinet7 from "../../asset/images/office furniture/file cabinet/7.jpg";
import fileCabinet8 from "../../asset/images/office furniture/file cabinet/8.jpg";
import fileCabinet9 from "../../asset/images/office furniture/file cabinet/9.jpg";

//           Low height Cabinet
import lowCabinet1 from "../../asset/images/office furniture/low cabinet/1.jpg";
import lowCabinet2 from "../../asset/images/office furniture/low cabinet/2.jpg";
import lowCabinet3 from "../../asset/images/office furniture/low cabinet/3.jpg";
import lowCabinet4 from "../../asset/images/office furniture/low cabinet/4.jpg";
import lowCabinet5 from "../../asset/images/office furniture/low cabinet/5.jpg";
import lowCabinet6 from "../../asset/images/office furniture/low cabinet/6.jpg";
import lowCabinet7 from "../../asset/images/office furniture/low cabinet/7.jpg";
import lowCabinet8 from "../../asset/images/office furniture/low cabinet/8.jpg";
import lowCabinet9 from "../../asset/images/office furniture/low cabinet/9.jpg";

//   director table
import directorTable1 from "../../asset/images/office furniture/new director table/1.jpg";
import directorTable2 from "../../asset/images/office furniture/new director table/2.jpg";
import directorTable3 from "../../asset/images/office furniture/new director table/3.jpg";
import directorTable4 from "../../asset/images/office furniture/new director table/4.jpg";
import directorTable5 from "../../asset/images/office furniture/new director table/5.jpg";
import directorTable6 from "../../asset/images/office furniture/new director table/6.jpg";
import directorTable7 from "../../asset/images/office furniture/new director table/7.jpg";
import directorTable8 from "../../asset/images/office furniture/new director table/8.jpg";
import directorTable9 from "../../asset/images/office furniture/new director table/9.jpg";
import directorTable11 from "../../asset/images/office furniture/new director table/11.jpg";

// Industrial rack
import rack1 from "../../asset/images/Industrial rack/01.jpg";
import rack2 from "../../asset/images/Industrial rack/02.jpg";
import rack3 from "../../asset/images/Industrial rack/03.jpg";
import rack4 from "../../asset/images/Industrial rack/04.jpg";
import rack5 from "../../asset/images/Industrial rack/05.jpg";
import rack6 from "../../asset/images/Industrial rack/06.jpg";
import rack7 from "../../asset/images/Industrial rack/07.jpg";
import rack8 from "../../asset/images/Industrial rack/08.jpg";
import rack9 from "../../asset/images/Industrial rack/09.jpg";
import rack10 from "../../asset/images/Industrial rack/10.jpg";
import rack11 from "../../asset/images/Industrial rack/11.jpg";
import rack12 from "../../asset/images/Industrial rack/12.jpg";



// conference table

import con_table1 from "../../asset/images/Chair/conference table/conferenceTable01.jpg";
import con_table2 from "../../asset/images/Chair/conference table/conferenceTable02.jpg";
import con_table3 from "../../asset/images/Chair/conference table/conferenceTable03.jpg";
import con_table4 from "../../asset/images/Chair/conference table/conferenceTable04.jpg";
import con_table5 from "../../asset/images/Chair/conference table/conferenceTable05.jpg";
import con_table6 from "../../asset/images/Chair/conference table/conferenceTable06.jpg";
import con_table7 from "../../asset/images/Chair/conference table/conferenceTable07.jpg";
import con_table8 from "../../asset/images/Chair/conference table/conferenceTable08.jpg";

// Chair Image
import chair1 from "../../asset/images/Chair/01.jpg";
import chair2 from "../../asset/images/Chair/02.jpg";
import chair3 from "../../asset/images/Chair/03.jpg";
import chair4 from "../../asset/images/Chair/04.jpg";
import chair5 from "../../asset/images/Chair/05.jpg";
import chair6 from "../../asset/images/Chair/06.jpg";
import chair7 from "../../asset/images/Chair/07.jpg";
import chair8 from "../../asset/images/Chair/08.jpg";
import chair9 from "../../asset/images/Chair/09.jpg";
import chair10 from "../../asset/images/Chair/10.jpg";
import chair11 from "../../asset/images/Chair/11.jpg";
import chair12 from "../../asset/images/Chair/12.jpg";
import chair13 from "../../asset/images/Chair/13.jpg";
import chair14 from "../../asset/images/Chair/14.jpg";
import chair15 from "../../asset/images/Chair/15.jpg";
import chair16 from "../../asset/images/Chair/16.jpg";
import chair17 from "../../asset/images/Chair/17.jpg";
import chair18 from "../../asset/images/Chair/18.jpg";
import chair19 from "../../asset/images/Chair/19.jpg";
import chair20 from "../../asset/images/Chair/20.jpg";
import chair21 from "../../asset/images/Chair/21.jpg";
import chair22 from "../../asset/images/Chair/22.jpg";
import chair23 from "../../asset/images/Chair/23.jpg";
import chair24 from "../../asset/images/Chair/24.jpg";
import chair25 from "../../asset/images/Chair/25.jpg";
import chair26 from "../../asset/images/Chair/26.jpg";
import chair27 from "../../asset/images/Chair/27.jpg";
import chair28 from "../../asset/images/Chair/28.jpg";
import chair29 from "../../asset/images/Chair/29.jpg";
import chair30 from "../../asset/images/Chair/30.jpg";
import chair31 from "../../asset/images/Chair/31.jpg";
import chair32 from "../../asset/images/Chair/32.jpg";
import chair33 from "../../asset/images/Chair/33.jpg";
import chair34 from "../../asset/images/Chair/34.jpg";
import chair35 from "../../asset/images/Chair/35.jpg";
import chair36 from "../../asset/images/Chair/36.jpg";
import chair37 from "../../asset/images/Chair/37.jpg";
import chair38 from "../../asset/images/Chair/38.jpg";
import chair39 from "../../asset/images/Chair/39.jpg";
import chair40 from "../../asset/images/Chair/40.jpg";
import chair41 from "../../asset/images/Chair/41.jpg";
import chair42 from "../../asset/images/Chair/42.jpg";
import chair43 from "../../asset/images/Chair/43.jpg";
import chair44 from "../../asset/images/Chair/44.jpg";
import chair45 from "../../asset/images/Chair/45.jpg";
import chair46 from "../../asset/images/Chair/46.jpg";
import chair47 from "../../asset/images/Chair/47.jpg";
import chair48 from "../../asset/images/Chair/48.jpg";
import chair49 from "../../asset/images/Chair/49.jpg";
import chair50 from "../../asset/images/Chair/50.jpg";
import chair51 from "../../asset/images/Chair/51.jpg";
import chair52 from "../../asset/images/Chair/52.jpg";
import chair53 from "../../asset/images/Chair/53.jpg";
import chair54 from "../../asset/images/Chair/54.jpg";
import chair55 from "../../asset/images/Chair/55.jpg";
import chair56 from "../../asset/images/Chair/56.jpg";
import chair57 from "../../asset/images/Chair/57.jpg";
import chair58 from "../../asset/images/Chair/58.jpg";
import chair59 from "../../asset/images/Chair/59.jpg";
import chair60 from "../../asset/images/Chair/60.jpg";
import chair61 from "../../asset/images/Chair/61.jpg";
import chair62 from "../../asset/images/Chair/62.jpg";
import chair63 from "../../asset/images/Chair/63.jpg";
import chair64 from "../../asset/images/Chair/64.jpg";
import chair65 from "../../asset/images/Chair/65.jpg";
import chair66 from "../../asset/images/Chair/66.jpg";
import chair67 from "../../asset/images/Chair/67.jpg";
import chair68 from "../../asset/images/Chair/68.png";
import chair69 from "../../asset/images/Chair/69.jpg";
import chair70 from "../../asset/images/Chair/70.jpg";
import chair71 from "../../asset/images/Chair/71.jpg";
import chair72 from "../../asset/images/Chair/72.jpg";
import chair073 from "../../asset/images/Chair/073.jpg";
import chair73 from "../../asset/images/Chair/73.jpg";
import chair74 from "../../asset/images/Chair/74.jpg";
import chair75 from "../../asset/images/Chair/75.jpg";
import chair76 from "../../asset/images/Chair/76.jpg";
import chair77 from "../../asset/images/Chair/77.jpg";
import chair077 from "../../asset/images/Chair/077.jpg";
import chair78 from "../../asset/images/Chair/78.jpg";
import chair078 from "../../asset/images/Chair/078.jpg";
import chair79 from "../../asset/images/Chair/79.jpg";
import chair079 from "../../asset/images/Chair/079.jpg";
import chair80 from "../../asset/images/Chair/80.jpg";
import chair080 from "../../asset/images/Chair/080.jpg";
import chair81 from "../../asset/images/Chair/81.jpg";
import chair82 from "../../asset/images/Chair/82.jpg";
import chair83 from "../../asset/images/Chair/83.jpg";
import chair84 from "../../asset/images/Chair/84.jpg";
import chair85 from "../../asset/images/Chair/85.jpg";
import chair86 from "../../asset/images/Chair/86.jpg";
import chair87 from "../../asset/images/Chair/87.jpg";
import chair88 from "../../asset/images/Chair/88.jpg";
import chair89 from "../../asset/images/Chair/89.jpg";
import chair90 from "../../asset/images/Chair/90.jpg";
import chair91 from "../../asset/images/Chair/91.jpg";
import chair92 from "../../asset/images/Chair/92.jpg";
import chair93 from "../../asset/images/Chair/93.jpg";
import chair94 from "../../asset/images/Chair/94.jpg";
import chair95 from "../../asset/images/Chair/95.jpg";
import chair96 from "../../asset/images/Chair/96.jpg";

//                           Plastic household                       //
//                           Kitchen Almirah
import kitchenalmirah1 from "../../asset/images/plastic/household/kitchen almirah/01.jpg";

//                        kitchen rack 
import kitchenrack1 from "../../asset/images/plastic/household/kitchen rack/01.jpg";
import kitchenrack2 from "../../asset/images/plastic/household/kitchen rack/02.jpg";
import kitchenrack3 from "../../asset/images/plastic/household/kitchen rack/03.jpg";
import kitchenrack4 from "../../asset/images/plastic/household/kitchen rack/04.jpg";
import kitchenrack5 from "../../asset/images/plastic/household/kitchen rack/05.jpg";
import kitchenrack6 from "../../asset/images/plastic/household/kitchen rack/06.jpg";
import kitchenrack7 from "../../asset/images/plastic/household/kitchen rack/07.jpg";
import kitchenrack8 from "../../asset/images/plastic/household/kitchen rack/08.jpg";


//                          plastic household
//                       upcoming (plastic hosehold)
import plasticUpcoming1 from "../../asset/images/plastic/household items/upcoming/u1.jpg";
import plasticUpcoming2 from "../../asset/images/plastic/household items/upcoming/u2.jpg";
import plasticUpcoming3 from "../../asset/images/plastic/household items/upcoming/u3.jpg";
import plasticUpcoming4 from "../../asset/images/plastic/household items/upcoming/u4.jpg";
import plasticUpcoming5 from "../../asset/images/plastic/household items/upcoming/u5.jpg";
import plasticUpcoming6 from "../../asset/images/plastic/household items/upcoming/u6.jpg";
import plasticUpcoming7 from "../../asset/images/plastic/household items/upcoming/u7.jpg";
import plasticUpcoming8 from "../../asset/images/plastic/household items/upcoming/u8.jpg";
import plasticUpcoming9 from "../../asset/images/plastic/household items/upcoming/u9.jpg";
import plasticUpcoming10 from "../../asset/images/plastic/household items/upcoming/u10.jpg";
import plasticUpcoming11 from "../../asset/images/plastic/household items/upcoming/u11.jpg";
import plasticUpcoming12 from "../../asset/images/plastic/household items/upcoming/u12.jpg";
import plasticUpcoming13 from "../../asset/images/plastic/household items/upcoming/u13.jpg";
import plasticUpcoming14 from "../../asset/images/plastic/household items/upcoming/u14.jpg";
import plasticUpcoming15 from "../../asset/images/plastic/household items/upcoming/u15.jpg";
import plasticUpcoming16 from "../../asset/images/plastic/household items/upcoming/u16.jpg";


//                         plastic item(household)
import plastichousehold1 from "../../asset/images/plastic/household items/01.jpg";
import plastichousehold2 from "../../asset/images/plastic/household items/02.jpg";
import plastichousehold3 from "../../asset/images/plastic/household items/03.jpg";
import plastichousehold4 from "../../asset/images/plastic/household items/04.jpg";
import plastichousehold5 from "../../asset/images/plastic/household items/05.jpg";
import plastichousehold6 from "../../asset/images/plastic/household items/06.jpg";
import plastichousehold7 from "../../asset/images/plastic/household items/07.jpg";
import plastichousehold8 from "../../asset/images/plastic/household items/08.jpg";
import plastichousehold9 from "../../asset/images/plastic/household items/09.jpg";
import plastichousehold10 from "../../asset/images/plastic/household items/10.jpg";
import plastichousehold11 from "../../asset/images/plastic/household items/11.jpg";
import plastichousehold12 from "../../asset/images/plastic/household items/12.jpg";
import plastichousehold13 from "../../asset/images/plastic/household items/13.jpg";
import plastichousehold14 from "../../asset/images/plastic/household items/14.jpg";
import plastichousehold15 from "../../asset/images/plastic/household items/15.jpg";
import plastichousehold16 from "../../asset/images/plastic/household items/16.jpg";
import plastichousehold17 from "../../asset/images/plastic/household items/17.jpg";
import plastichousehold18 from "../../asset/images/plastic/household items/18.jpg";
import plastichousehold19 from "../../asset/images/plastic/household items/19.jpg";
import plastichousehold20 from "../../asset/images/plastic/household items/20.jpg";
import plastichousehold21 from "../../asset/images/plastic/household items/21.jpg";
import plastichousehold22 from "../../asset/images/plastic/household items/22.jpg";
import plastichousehold23 from "../../asset/images/plastic/household items/23.jpg";
import plastichousehold24 from "../../asset/images/plastic/household items/24.jpg";
import plastichousehold25 from "../../asset/images/plastic/household items/25.jpg";
import plastichousehold26 from "../../asset/images/plastic/household items/26.jpg";
import plastichousehold27 from "../../asset/images/plastic/household items/27.jpg";
import plastichousehold28 from "../../asset/images/plastic/household items/28.jpg";
import plastichousehold29 from "../../asset/images/plastic/household items/29.jpg";
import plastichousehold30 from "../../asset/images/plastic/household items/30.jpg";
import plastichousehold31 from "../../asset/images/plastic/household items/31.jpg";
import plastichousehold32 from "../../asset/images/plastic/household items/32.jpg";
import plastichousehold33 from "../../asset/images/plastic/household items/33.jpg";
import plastichousehold34 from "../../asset/images/plastic/household items/34.jpg";
import plastichousehold35 from "../../asset/images/plastic/household items/35.jpg";
import plastichousehold36 from "../../asset/images/plastic/household items/36.jpg";
import plastichousehold37 from "../../asset/images/plastic/household items/37.jpg";
import plastichousehold38 from "../../asset/images/plastic/household items/38.jpg";
import plastichousehold39 from "../../asset/images/plastic/household items/39.jpg";
import plastichousehold40 from "../../asset/images/plastic/household items/40.jpg";
import plastichousehold41 from "../../asset/images/plastic/household items/41.jpg";
import plastichousehold42 from "../../asset/images/plastic/household items/42.jpg";
import plastichousehold43 from "../../asset/images/plastic/household items/43.jpg";
import plastichousehold44 from "../../asset/images/plastic/household items/44.jpg";
import plastichousehold45 from "../../asset/images/plastic/household items/45.jpg";
import plastichousehold46 from "../../asset/images/plastic/household items/46.jpg";
import plastichousehold47 from "../../asset/images/plastic/household items/47.jpg";
import plastichousehold48 from "../../asset/images/plastic/household items/48.jpg";
import plastichousehold49 from "../../asset/images/plastic/household items/49.jpg";
import plastichousehold50 from "../../asset/images/plastic/household items/50.jpg";
import plastichousehold51 from "../../asset/images/plastic/household items/51.jpg";
import plastichousehold52 from "../../asset/images/plastic/household items/52.jpg";
import plastichousehold53 from "../../asset/images/plastic/household items/53.jpg";
import plastichousehold54 from "../../asset/images/plastic/household items/54.jpg";
import plastichousehold55 from "../../asset/images/plastic/household items/55.jpg";
import plastichousehold56 from "../../asset/images/plastic/household items/56.jpg";
import plastichousehold57 from "../../asset/images/plastic/household items/57.jpg";
import plastichousehold58 from "../../asset/images/plastic/household items/58.jpg";
import plastichousehold59 from "../../asset/images/plastic/household items/59.jpg";
import plastichousehold60 from "../../asset/images/plastic/household items/60.jpg";
import plastichousehold61 from "../../asset/images/plastic/household items/61.jpg";
import plastichousehold62 from "../../asset/images/plastic/household items/62.jpg";
import plastichousehold63 from "../../asset/images/plastic/household items/63.jpg";
import plastichousehold64 from "../../asset/images/plastic/household items/64.jpg";
import plastichousehold65 from "../../asset/images/plastic/household items/65.jpg";
import plastichousehold66 from "../../asset/images/plastic/household items/66.jpg";
import plastichousehold67 from "../../asset/images/plastic/household items/67.jpg";
import plastichousehold68 from "../../asset/images/plastic/household items/68.jpg";
import plastichousehold69 from "../../asset/images/plastic/household items/69.jpg";
import plastichousehold70 from "../../asset/images/plastic/household items/70.jpg";
import plastichousehold71 from "../../asset/images/plastic/household items/71.jpg";
import plastichousehold72 from "../../asset/images/plastic/household items/72.jpg";
import plastichousehold73 from "../../asset/images/plastic/household items/73.jpg";
import plastichousehold74 from "../../asset/images/plastic/household items/74.jpg";
import plastichousehold75 from "../../asset/images/plastic/household items/75.jpg";
import plastichousehold76 from "../../asset/images/plastic/household items/76.jpg";

//                            chair
import pchair1 from "../../asset/images/plastic/household/chair/01.jpg";
import pchair2 from "../../asset/images/plastic/household/chair/02.jpg";
import pchair3 from "../../asset/images/plastic/household/chair/03.jpg";
import pchair4 from "../../asset/images/plastic/household/chair/04.jpg";
import pchair5 from "../../asset/images/plastic/household/chair/05.jpg";
import pchair6 from "../../asset/images/plastic/household/chair/06.jpg";
import pchair7 from "../../asset/images/plastic/household/chair/07.jpg";
import pchair8 from "../../asset/images/plastic/household/chair/08.jpg";
import pchair9 from "../../asset/images/plastic/household/chair/09.jpg";
import pchair10 from "../../asset/images/plastic/household/chair/10.jpg";
import pchair11 from "../../asset/images/plastic/household/chair/11.jpg";
import pchair12 from "../../asset/images/plastic/household/chair/12.jpg";
import pchair13 from "../../asset/images/plastic/household/chair/13.jpg";
import pchair14 from "../../asset/images/plastic/household/chair/14.jpg";
import pchair15 from "../../asset/images/plastic/household/chair/15.jpg";
import pchair16 from "../../asset/images/plastic/household/chair/16.jpg";
import pchair17 from "../../asset/images/plastic/household/chair/17.jpg";
import pchair18 from "../../asset/images/plastic/household/chair/18.jpg";
import pchair19 from "../../asset/images/plastic/household/chair/19.jpg";
import pchair20 from "../../asset/images/plastic/household/chair/20.jpg";
import pchair21 from "../../asset/images/plastic/household/chair/21.jpg";
import pchair22 from "../../asset/images/plastic/household/chair/22.jpg";
import pchair23 from "../../asset/images/plastic/household/chair/23.jpg";
import pchair24 from "../../asset/images/plastic/household/chair/24.jpg";
import pchair25 from "../../asset/images/plastic/household/chair/25.jpg";
import pchair26 from "../../asset/images/plastic/household/chair/26.jpg";
import pchair27 from "../../asset/images/plastic/household/chair/27.jpg";
import pchair28 from "../../asset/images/plastic/household/chair/28.jpg";
import pchair29 from "../../asset/images/plastic/household/chair/29.jpg";
import pchair30 from "../../asset/images/plastic/household/chair/30.jpg";
import pchair31 from "../../asset/images/plastic/household/chair/31.jpg";
import pchair32 from "../../asset/images/plastic/household/chair/32.jpg";
import pchair33 from "../../asset/images/plastic/household/chair/33.jpg";
import pchair34 from "../../asset/images/plastic/household/chair/34.jpg";

//                            plastic furniture
//                            waredrobe 
import waredrobe1 from "../../asset/images/plastic/furniture/waredrobe/01.jpg";
import waredrobe2 from "../../asset/images/plastic/furniture/waredrobe/02.jpg";
import waredrobe3 from "../../asset/images/plastic/furniture/waredrobe/03.jpg";
import waredrobe4 from "../../asset/images/plastic/furniture/waredrobe/04.jpg";
import waredrobe5 from "../../asset/images/plastic/furniture/waredrobe/05.jpg";
import waredrobe6 from "../../asset/images/plastic/furniture/waredrobe/06.jpg";
import waredrobe7 from "../../asset/images/plastic/furniture/waredrobe/07.jpg";
import waredrobe8 from "../../asset/images/plastic/furniture/waredrobe/08.jpg";
import waredrobe9 from "../../asset/images/plastic/furniture/waredrobe/09.jpg";
import waredrobe10 from "../../asset/images/plastic/furniture/waredrobe/10.jpg";
import waredrobe11 from "../../asset/images/plastic/furniture/waredrobe/11.jpg";
import waredrobe12 from "../../asset/images/plastic/furniture/waredrobe/12.jpg";
import waredrobe13 from "../../asset/images/plastic/furniture/waredrobe/13.jpg";
import waredrobe14 from "../../asset/images/plastic/furniture/waredrobe/14.jpg";
import waredrobe15 from "../../asset/images/plastic/furniture/waredrobe/15.jpg";
import waredrobe16 from "../../asset/images/plastic/furniture/waredrobe/16.jpg";
import waredrobe17 from "../../asset/images/plastic/furniture/waredrobe/17.jpg";
import waredrobe18 from "../../asset/images/plastic/furniture/waredrobe/18.jpg";
import waredrobe19 from "../../asset/images/plastic/furniture/waredrobe/19.jpg";
import waredrobe20 from "../../asset/images/plastic/furniture/waredrobe/20.jpg";
import waredrobe21 from "../../asset/images/plastic/furniture/waredrobe/21.jpg";
import waredrobe22 from "../../asset/images/plastic/furniture/waredrobe/22.jpg";
import waredrobe23 from "../../asset/images/plastic/furniture/waredrobe/23.jpg";
import waredrobe24 from "../../asset/images/plastic/furniture/waredrobe/24.jpg";
import waredrobe25 from "../../asset/images/plastic/furniture/waredrobe/25.jpg";
import waredrobe26 from "../../asset/images/plastic/furniture/waredrobe/26.jpg";
import waredrobe27 from "../../asset/images/plastic/furniture/waredrobe/27.jpg";
import waredrobe28 from "../../asset/images/plastic/furniture/waredrobe/28.jpg";
import waredrobe29 from "../../asset/images/plastic/furniture/waredrobe/29.jpg";
import waredrobe30 from "../../asset/images/plastic/furniture/waredrobe/30.jpg";
import waredrobe31 from "../../asset/images/plastic/furniture/waredrobe/31.jpg";
import waredrobe32 from "../../asset/images/plastic/furniture/waredrobe/32.jpg";
import waredrobe33 from "../../asset/images/plastic/furniture/waredrobe/33.jpg";
import waredrobe34 from "../../asset/images/plastic/furniture/waredrobe/34.jpg";
import waredrobe35 from "../../asset/images/plastic/furniture/waredrobe/35.jpg";
import waredrobe36 from "../../asset/images/plastic/furniture/waredrobe/36.jpg";
import waredrobe37 from "../../asset/images/plastic/furniture/waredrobe/37.jpg";
import waredrobe38 from "../../asset/images/plastic/furniture/waredrobe/38.jpg";
import waredrobe39 from "../../asset/images/plastic/furniture/waredrobe/39.jpg";
import waredrobe40 from "../../asset/images/plastic/furniture/waredrobe/40.jpg";

//                     plastic dinning table

import dTable1 from "../../asset/images/plastic/furniture/table/1.jpg";
import dTable2 from "../../asset/images/plastic/furniture/table/2.jpg";
import dTable3 from "../../asset/images/plastic/furniture/table/3.jpg";
import dTable4 from "../../asset/images/plastic/furniture/table/4.jpg";
import dTable5 from "../../asset/images/plastic/furniture/table/5.jpg";
import dTable6 from "../../asset/images/plastic/furniture/table/6.jpg";
import dTable7 from "../../asset/images/plastic/furniture/table/7.jpg";
import dTable8 from "../../asset/images/plastic/furniture/table/8.jpg";
import dTable9 from "../../asset/images/plastic/furniture/table/9.jpg";
import dTable10 from "../../asset/images/plastic/furniture/table/10.jpg";
import dTable11 from "../../asset/images/plastic/furniture/table/11.jpg";
import dTable12 from "../../asset/images/plastic/furniture/table/12.jpg";
import dTable13 from "../../asset/images/plastic/furniture/table/13.jpg";
import dTable14 from "../../asset/images/plastic/furniture/table/14.jpg";
import dTable15 from "../../asset/images/plastic/furniture/table/15.jpg";
import dTable16 from "../../asset/images/plastic/furniture/table/16.jpg";
import dTable17 from "../../asset/images/plastic/furniture/table/17.jpg";
import dTable18 from "../../asset/images/plastic/furniture/table/18.jpg";
import dTable19 from "../../asset/images/plastic/furniture/table/19.jpg";
import dTable20 from "../../asset/images/plastic/furniture/table/20.jpg";
import dTable21 from "../../asset/images/plastic/furniture/table/21.jpg";
import dTable22 from "../../asset/images/plastic/furniture/table/22.jpg";
import dTable23 from "../../asset/images/plastic/furniture/table/23.jpg";
import dTable24 from "../../asset/images/plastic/furniture/table/24.jpg";
import dTable25 from "../../asset/images/plastic/furniture/table/25.jpg";
import dTable26 from "../../asset/images/plastic/furniture/table/26.jpg";
import dTable27 from "../../asset/images/plastic/furniture/table/27.jpg";
import dTable28 from "../../asset/images/plastic/furniture/table/28.jpg";

//     Toys

import toy1 from "../../asset/images/plastic/toys/01.jpg";
import toy2 from "../../asset/images/plastic/toys/02.jpg";
import toy3 from "../../asset/images/plastic/toys/03.jpg";
import toy4 from "../../asset/images/plastic/toys/04.jpg";
import toy5 from "../../asset/images/plastic/toys/05.jpg";
import toy6 from "../../asset/images/plastic/toys/06.jpg";
import toy7 from "../../asset/images/plastic/toys/07.jpg";
import toy8 from "../../asset/images/plastic/toys/08.jpg";

//          lged

import lged1 from "../../asset/images/lged/lged1.jpg";
// import lged2 from "../../asset/images/lged/lged2.jpg"
// import lged3 from "../../asset/images/lged/lged3.jpg"
// import lged4 from "../../asset/images/lged/lged4.jpg"
// import lged5 from "../../asset/images/lged/lged5.jpg"
// import lged6 from "../../asset/images/lged/lged6.jpg"
import lged7 from "../../asset/images/lged/lged7.jpg";
import lged8 from "../../asset/images/lged/lged8.jpg";
// import lged9 from "../../asset/images/lged/lged9.jpg"
import lged10 from "../../asset/images/lged/lged10.jpg";
import lged11 from "../../asset/images/lged/lged11.jpg";

//           upvc
import upvc1 from "../../asset/images/upvc/upvc pipe/01.jpeg";
import upvc2 from "../../asset/images/upvc/upvc pipe/02.jpeg";
import upvc3 from "../../asset/images/upvc/upvc pipe/02.jpeg";
import upvc6 from "../../asset/images/upvc/upvc pipe/06.jpeg";
import upvc7 from "../../asset/images/upvc/upvc pipe/07.jpeg";
import upvc8 from "../../asset/images/upvc/upvc pipe/08.jpeg";
import upvc9 from "../../asset/images/upvc/upvc pipe/09.jpeg";
import upvc10 from "../../asset/images/upvc/upvc pipe/10.jpeg";
import upvc11 from "../../asset/images/upvc/upvc pipe/11.jpeg";
import upvc12 from "../../asset/images/upvc/upvc pipe/12.jpeg";
import upvc13 from "../../asset/images/upvc/upvc pipe/13.jpeg";
import upvc14 from "../../asset/images/upvc/upvc pipe/14.jpeg";
import upvc18 from "../../asset/images/upvc/upvc pipe/18.jpeg";

//                            fittings(upvc) 

import fittings1 from "../../asset/images/upvc/fittings/01.png";
import fittings2 from "../../asset/images/upvc/fittings/02.png";
import fittings3 from "../../asset/images/upvc/fittings/03.png";
import fittings4 from "../../asset/images/upvc/fittings/04.png";
import fittings5 from "../../asset/images/upvc/fittings/05.png";
import fittings6 from "../../asset/images/upvc/fittings/06.jpeg";
import fittings7 from "../../asset/images/upvc/fittings/07.png";
import fittings8 from "../../asset/images/upvc/fittings/08.png";
import fittings9 from "../../asset/images/upvc/fittings/09.png";
import fittings10 from "../../asset/images/upvc/fittings/10.png";
import fittings11 from "../../asset/images/upvc/fittings/11.jpeg";
import fittings12 from "../../asset/images/upvc/fittings/12.png";
import fittings13 from "../../asset/images/upvc/fittings/13.png";
import fittings14 from "../../asset/images/upvc/fittings/14.png";
import fittings15 from "../../asset/images/upvc/fittings/15.png";
import fittings16 from "../../asset/images/upvc/fittings/16.png";
import fittings17 from "../../asset/images/upvc/fittings/17.jpeg";
import fittings18 from "../../asset/images/upvc/fittings/18.png";
import fittings19 from "../../asset/images/upvc/fittings/19.png";
import fittings20 from "../../asset/images/upvc/fittings/20.png";
import fittings21 from "../../asset/images/upvc/fittings/21.png";
import fittings22 from "../../asset/images/upvc/fittings/22.png";
import fittings23 from "../../asset/images/upvc/fittings/23.png";
import fittings24 from "../../asset/images/upvc/fittings/24.png";
import fittings25 from "../../asset/images/upvc/fittings/25.png";
import fittings26 from "../../asset/images/upvc/fittings/26.png";
import fittings27 from "../../asset/images/upvc/fittings/27.png";
import fittings28 from "../../asset/images/upvc/fittings/28.png";
import fittings29 from "../../asset/images/upvc/fittings/29.png";
import fittings30 from "../../asset/images/upvc/fittings/30.png";
import fittings31 from "../../asset/images/upvc/fittings/31.png";
import fittings32 from "../../asset/images/upvc/fittings/32.png";
import fittings33 from "../../asset/images/upvc/fittings/33.png";
import fittings34 from "../../asset/images/upvc/fittings/34.png";
import fittings35 from "../../asset/images/upvc/fittings/35.png";
import fittings36 from "../../asset/images/upvc/fittings/36.png";
import fittings37 from "../../asset/images/upvc/fittings/37.jpg";
import fittings38 from "../../asset/images/upvc/fittings/38.jpg";
import fittings39 from "../../asset/images/upvc/fittings/39.jpg";
import fittings40 from "../../asset/images/upvc/fittings/40.jpg";

//         Tubewell
import tubewell1 from "../../asset/images/upvc/Tubewell/1.jpeg";
import tubewell2 from "../../asset/images/upvc/Tubewell/2.jpeg";
import tubewell3 from "../../asset/images/upvc/Tubewell/3.jpeg";
import tubewell4 from "../../asset/images/upvc/Tubewell/4.jpeg";
import tubewell5 from "../../asset/images/upvc/Tubewell/5.jpeg";
import tubewell6 from "../../asset/images/upvc/Tubewell/6.jpeg";
import tubewell7 from "../../asset/images/upvc/Tubewell/7.jpeg";
import tubewell8 from "../../asset/images/upvc/Tubewell/8.jpeg";

//              Home Furniture

//               Bed
import bed1 from "../../asset/images/Home Furniture/Bed/bed01.jpg";
import bed2 from "../../asset/images/Home Furniture/Bed/bed02.jpg";
import bed3 from "../../asset/images/Home Furniture/Bed/bed03.jpg";
import bed4 from "../../asset/images/Home Furniture/Bed/bed04.jpg";
import bed5 from "../../asset/images/Home Furniture/Bed/bed05.jpg";
import bed6 from "../../asset/images/Home Furniture/Bed/bed06.jpg";
import bed7 from "../../asset/images/Home Furniture/Bed/bed07.jpg";
import bed8 from "../../asset/images/Home Furniture/Bed/bed08.jpg";
import bed9 from "../../asset/images/Home Furniture/Bed/bed09.jpg";
import bed10 from "../../asset/images/Home Furniture/Bed/bed10.jpg";
import bed11 from "../../asset/images/Home Furniture/Bed/bed11.jpg";
import bed12 from "../../asset/images/Home Furniture/Bed/bed12.jpg";
import bed13 from "../../asset/images/Home Furniture/Bed/bed13.jpg";
import bed14 from "../../asset/images/Home Furniture/Bed/bed14.jpg";
import bed15 from "../../asset/images/Home Furniture/Bed/bed15.jpg";
import bed16 from "../../asset/images/Home Furniture/Bed/bed16.jpg";
import bed17 from "../../asset/images/Home Furniture/Bed/bed17.jpg";
import bed18 from "../../asset/images/Home Furniture/Bed/bed18.jpg";
import bed19 from "../../asset/images/Home Furniture/Bed/bed19.jpg";
import bed20 from "../../asset/images/Home Furniture/Bed/bed20.jpg";
import bed21 from "../../asset/images/Home Furniture/Bed/bed21.jpg";
import bed22 from "../../asset/images/Home Furniture/Bed/bed22.jpg";
import bed23 from "../../asset/images/Home Furniture/Bed/bed23.jpg";
import bed24 from "../../asset/images/Home Furniture/Bed/bed24.jpg";
import bed25 from "../../asset/images/Home Furniture/Bed/bed25.jpg";
import bed26 from "../../asset/images/Home Furniture/Bed/bed26.jpg";
import bed27 from "../../asset/images/Home Furniture/Bed/bed27.jpg";
import bed28 from "../../asset/images/Home Furniture/Bed/bed28.jpg";
import bed29 from "../../asset/images/Home Furniture/Bed/bed29.jpg";
import bed30 from "../../asset/images/Home Furniture/Bed/bed30.jpg";
import bed31 from "../../asset/images/Home Furniture/Bed/bed31.jpg";
import bed32 from "../../asset/images/Home Furniture/Bed/bed32.jpg";
import bed33 from "../../asset/images/Home Furniture/Bed/bed33.jpg";
import bed34 from "../../asset/images/Home Furniture/Bed/bed34.jpg";
import bed35 from "../../asset/images/Home Furniture/Bed/bed35.jpg";
import bed36 from "../../asset/images/Home Furniture/Bed/bed36.jpg";
import bed37 from "../../asset/images/Home Furniture/Bed/bed37.jpg";
import bed38 from "../../asset/images/Home Furniture/Bed/bed38.jpg";
import bed39 from "../../asset/images/Home Furniture/Bed/bed39.jpg";
import bed40 from "../../asset/images/Home Furniture/Bed/bed40.jpg";
import bed41 from "../../asset/images/Home Furniture/Bed/bed41.jpg";
import bed42 from "../../asset/images/Home Furniture/Bed/bed42.jpg";
import bed43 from "../../asset/images/Home Furniture/Bed/bed43.jpg";
import bed44 from "../../asset/images/Home Furniture/Bed/bed44.jpg";

//                Bed Side Table
import bedSideTable1 from "../../asset/images/Home Furniture/Bed side table/1.jpg";
import bedSideTable2 from "../../asset/images/Home Furniture/Bed side table/2.jpg";
import bedSideTable3 from "../../asset/images/Home Furniture/Bed side table/3.jpg";
import bedSideTable4 from "../../asset/images/Home Furniture/Bed side table/4.jpg";
import bedSideTable5 from "../../asset/images/Home Furniture/Bed side table/5.jpg";
import bedSideTable6 from "../../asset/images/Home Furniture/Bed side table/6.jpg";
import bedSideTable7 from "../../asset/images/Home Furniture/Bed side table/7.jpg";
import bedSideTable8 from "../../asset/images/Home Furniture/Bed side table/8.jpg";
import bedSideTable9 from "../../asset/images/Home Furniture/Bed side table/9.jpg";

//                CupBoard
import cupboard1 from "../../asset/images/Home Furniture/cupboard/1.jpg";
import cupboard2 from "../../asset/images/Home Furniture/cupboard/2.jpg";
import cupboard3 from "../../asset/images/Home Furniture/cupboard/3.jpg";
import cupboard4 from "../../asset/images/Home Furniture/cupboard/4.jpg";
import cupboard5 from "../../asset/images/Home Furniture/cupboard/5.jpg";
import cupboard6 from "../../asset/images/Home Furniture/cupboard/6.jpg";
import cupboard7 from "../../asset/images/Home Furniture/cupboard/7.jpg";
import cupboard8 from "../../asset/images/Home Furniture/cupboard/8.jpg";
import cupboard9 from "../../asset/images/Home Furniture/cupboard/9.jpg";

//               Dressing Table
import dressingTable1 from "../../asset/images/Home Furniture/dressing table/1.jpg";
import dressingTable2 from "../../asset/images/Home Furniture/dressing table/2.jpg";
import dressingTable3 from "../../asset/images/Home Furniture/dressing table/3.jpg";
import dressingTable4 from "../../asset/images/Home Furniture/dressing table/4.jpg";
import dressingTable5 from "../../asset/images/Home Furniture/dressing table/5.jpg";
import dressingTable6 from "../../asset/images/Home Furniture/dressing table/6.jpg";
import dressingTable7 from "../../asset/images/Home Furniture/dressing table/7.jpg";
import dressingTable8 from "../../asset/images/Home Furniture/dressing table/8.jpg";
import dressingTable9 from "../../asset/images/Home Furniture/dressing table/9.jpg";

//                Sofa
import sofa1 from "../../asset/images/Home Furniture/sofa/1.jpg";
import sofa2 from "../../asset/images/Home Furniture/sofa/2.jpg";
import sofa3 from "../../asset/images/Home Furniture/sofa/3.jpg";
import sofa4 from "../../asset/images/Home Furniture/sofa/4.jpg";
import sofa5 from "../../asset/images/Home Furniture/sofa/5.jpg";
import sofa6 from "../../asset/images/Home Furniture/sofa/6.jpg";
import sofa7 from "../../asset/images/Home Furniture/sofa/7.jpg";
import sofa8 from "../../asset/images/Home Furniture/sofa/8.jpg";
import sofa9 from "../../asset/images/Home Furniture/sofa/9.jpg";
import sofa10 from "../../asset/images/Home Furniture/sofa/10.jpg";
// import sofa11 from "../../asset/images/Home Furniture/sofa/sofa11.jpg";
import sofa12 from "../../asset/images/Home Furniture/sofa/12.jpg";
import sofa13 from "../../asset/images/Home Furniture/sofa/13.jpg";
import sofa14 from "../../asset/images/Home Furniture/sofa/14.jpg";
import sofa15 from "../../asset/images/Home Furniture/sofa/15.jpg";
import sofa16 from "../../asset/images/Home Furniture/sofa/16.jpg";
import sofa17 from "../../asset/images/Home Furniture/sofa/17.jpg";
import sofa18 from "../../asset/images/Home Furniture/sofa/18.jpg";
import sofa19 from "../../asset/images/Home Furniture/sofa/19.jpg";
import sofa20 from "../../asset/images/Home Furniture/sofa/20.jpg";
import sofa21 from "../../asset/images/Home Furniture/sofa/21.jpg";
import sofa22 from "../../asset/images/Home Furniture/sofa/22.jpg";
import sofa23 from "../../asset/images/Home Furniture/sofa/23.jpg";
import sofa24 from "../../asset/images/Home Furniture/sofa/24.jpg";

//         Dinning table

import dining1 from "../../asset/images/Home Furniture/dining/dining1.jpg";
import dining2 from "../../asset/images/Home Furniture/dining/dining2.jpg";
import dining3 from "../../asset/images/Home Furniture/dining/dining3.jpg";
import dining4 from "../../asset/images/Home Furniture/dining/dining4.jpg";
import dining5 from "../../asset/images/Home Furniture/dining/dining5.jpg";
import dining6 from "../../asset/images/Home Furniture/dining/dining6.jpg";
import dining7 from "../../asset/images/Home Furniture/dining/dining7.jpg";
import dining8 from "../../asset/images/Home Furniture/dining/dining8.jpg";
import dining9 from "../../asset/images/Home Furniture/dining/dining9.jpg";
import dining10 from "../../asset/images/Home Furniture/dining/dining10.jpg";
// import dining11 from "../../asset/images/Home Furniture/dining/dining11.jpg";
import dining12 from "../../asset/images/Home Furniture/dining/dining12.jpg";
import dining13 from "../../asset/images/Home Furniture/dining/dining13.jpg";
import dining14 from "../../asset/images/Home Furniture/dining/dining14.jpg";
import dining15 from "../../asset/images/Home Furniture/dining/dining15.jpg";
import dining16 from "../../asset/images/Home Furniture/dining/dining16.jpg";
import dining17 from "../../asset/images/Home Furniture/dining/dining17.jpg";
import dining18 from "../../asset/images/Home Furniture/dining/dining18.jpg";
import dining19 from "../../asset/images/Home Furniture/dining/dining19.jpg";

//                   dining wagon
import diningwagon1 from "../../asset/images/Home Furniture/dining/wagon/1.jpg";
import diningwagon2 from "../../asset/images/Home Furniture/dining/wagon/2.jpg";
import diningwagon3 from "../../asset/images/Home Furniture/dining/wagon/3.jpg";
import diningwagon4 from "../../asset/images/Home Furniture/dining/wagon/4.jpg";
import diningwagon5 from "../../asset/images/Home Furniture/dining/wagon/5.jpg";
import diningwagon6 from "../../asset/images/Home Furniture/dining/wagon/6.jpg";
import diningwagon7 from "../../asset/images/Home Furniture/dining/wagon/7.jpg";
import diningwagon8 from "../../asset/images/Home Furniture/dining/wagon/8.jpg";
import diningwagon9 from "../../asset/images/Home Furniture/dining/wagon/9.jpg";

//                TV cabinet
import tvCabinet1 from "../../asset/images/Home Furniture/Tv cabinet/1.jpg";
import tvCabinet2 from "../../asset/images/Home Furniture/Tv cabinet/2.jpg";
import tvCabinet3 from "../../asset/images/Home Furniture/Tv cabinet/3.jpg";
import tvCabinet4 from "../../asset/images/Home Furniture/Tv cabinet/4.jpg";
import tvCabinet5 from "../../asset/images/Home Furniture/Tv cabinet/5.jpg";
import tvCabinet6 from "../../asset/images/Home Furniture/Tv cabinet/6.jpg";
import tvCabinet7 from "../../asset/images/Home Furniture/Tv cabinet/7.jpg";
import tvCabinet8 from "../../asset/images/Home Furniture/Tv cabinet/8.jpg";
import tvCabinet9 from "../../asset/images/Home Furniture/Tv cabinet/9.jpg";

//       Book Shelf
import bookShelf1 from "../../asset/images/Home Furniture/book shelf/1.jpg";
import bookShelf2 from "../../asset/images/Home Furniture/book shelf/2.jpg";
import bookShelf3 from "../../asset/images/Home Furniture/book shelf/3.jpg";
import bookShelf4 from "../../asset/images/Home Furniture/book shelf/4.jpg";
import bookShelf5 from "../../asset/images/Home Furniture/book shelf/5.jpg";
import bookShelf6 from "../../asset/images/Home Furniture/book shelf/6.jpg";
import bookShelf7 from "../../asset/images/Home Furniture/book shelf/7.jpg";
import bookShelf8 from "../../asset/images/Home Furniture/book shelf/8.jpg";
import bookShelf9 from "../../asset/images/Home Furniture/book shelf/9.jpg";

//          shoe rack
import shoeRack1 from "../../asset/images/Home Furniture/shoe rack/1.jpg";
import shoeRack2 from "../../asset/images/Home Furniture/shoe rack/2.jpg";
import shoeRack3 from "../../asset/images/Home Furniture/shoe rack/3.jpg";
import shoeRack4 from "../../asset/images/Home Furniture/shoe rack/4.jpg";
import shoeRack5 from "../../asset/images/Home Furniture/shoe rack/5.jpg";
import shoeRack6 from "../../asset/images/Home Furniture/shoe rack/6.jpg";
import shoeRack7 from "../../asset/images/Home Furniture/shoe rack/7.jpg";
import shoeRack8 from "../../asset/images/Home Furniture/shoe rack/8.jpg";
import shoeRack9 from "../../asset/images/Home Furniture/shoe rack/9.jpg";

//             showcase
import showcase1 from "../../asset/images/Home Furniture/showcase/1.jpg";
import showcase2 from "../../asset/images/Home Furniture/showcase/2.jpg";
import showcase3 from "../../asset/images/Home Furniture/showcase/3.jpg";
import showcase4 from "../../asset/images/Home Furniture/showcase/4.jpg";
import showcase5 from "../../asset/images/Home Furniture/showcase/5.jpg";
import showcase6 from "../../asset/images/Home Furniture/showcase/6.jpg";
import showcase7 from "../../asset/images/Home Furniture/showcase/7.jpg";
import showcase8 from "../../asset/images/Home Furniture/showcase/8.jpg";
import showcase9 from "../../asset/images/Home Furniture/showcase/9.jpg";

//             computer table
import computerTable1 from "../../asset/images/Home Furniture/computer table/1.jpg";
import computerTable2 from "../../asset/images/Home Furniture/computer table/2.jpg";
import computerTable3 from "../../asset/images/Home Furniture/computer table/3.jpg";
import computerTable4 from "../../asset/images/Home Furniture/computer table/4.jpg";
import computerTable5 from "../../asset/images/Home Furniture/computer table/5.jpg";
import computerTable6 from "../../asset/images/Home Furniture/computer table/6.jpg";
import computerTable7 from "../../asset/images/Home Furniture/computer table/7.jpg";
import computerTable8 from "../../asset/images/Home Furniture/computer table/8.jpg";
import computerTable9 from "../../asset/images/Home Furniture/computer table/9.jpg";

//              Tea Table
import teaTable1 from "../../asset/images/Home Furniture/Tea Table/1.jpg";
import teaTable2 from "../../asset/images/Home Furniture/Tea Table/2.jpg";
import teaTable3 from "../../asset/images/Home Furniture/Tea Table/3.jpg";
import teaTable4 from "../../asset/images/Home Furniture/Tea Table/4.jpg";
import teaTable5 from "../../asset/images/Home Furniture/Tea Table/5.jpg";
import teaTable6 from "../../asset/images/Home Furniture/Tea Table/6.jpg";
import teaTable7 from "../../asset/images/Home Furniture/Tea Table/7.jpg";
import teaTable8 from "../../asset/images/Home Furniture/Tea Table/8.jpg";
import teaTable9 from "../../asset/images/Home Furniture/Tea Table/9.jpg";

//             center table
import centerTable1 from "../../asset/images/Home Furniture/center table/1.jpg";
import centerTable2 from "../../asset/images/Home Furniture/center table/2.jpg";
import centerTable3 from "../../asset/images/Home Furniture/center table/3.jpg";
import centerTable4 from "../../asset/images/Home Furniture/center table/4.jpg";
import centerTable5 from "../../asset/images/Home Furniture/center table/5.jpg";
import centerTable6 from "../../asset/images/Home Furniture/center table/6.jpg";
import centerTable7 from "../../asset/images/Home Furniture/center table/7.jpg";
import centerTable8 from "../../asset/images/Home Furniture/center table/8.jpg";
import centerTable9 from "../../asset/images/Home Furniture/center table/9.jpg";

//          Rocking Chair
import rockingChair1 from "../../asset/images/Home Furniture/Rocking Chair/1.jpg";
import rockingChair2 from "../../asset/images/Home Furniture/Rocking Chair/2.jpg";
import rockingChair3 from "../../asset/images/Home Furniture/Rocking Chair/3.jpg";
import rockingChair4 from "../../asset/images/Home Furniture/Rocking Chair/4.jpg";
import rockingChair5 from "../../asset/images/Home Furniture/Rocking Chair/5.jpg";
import rockingChair6 from "../../asset/images/Home Furniture/Rocking Chair/6.jpg";
import rockingChair7 from "../../asset/images/Home Furniture/Rocking Chair/7.jpg";
import rockingChair8 from "../../asset/images/Home Furniture/Rocking Chair/8.jpg";
import rockingChair9 from "../../asset/images/Home Furniture/Rocking Chair/9.jpg";




const ProductItems = [
    
//             plastic furniture 
//                waredrobe 
     
{ 
    id: 601,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe1,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25101/25102",
    color: "#231F20/#F3B016",
    // size:"L-88 x W-47.5 x H-137 cm",
    // size2:"L-66 x W-44 x H-127 cm",
    // material: "Polypropylene and ABS",
    button: "Details" 
  },
  {
    id: 602,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe2,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25101/25102",
    color: "#231F20/#F3B016",
    button: "Details"
  },
  {
    id: 603,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe3,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25103/25104",
    color: "#231F20/#F3B016",
    button: "Details"
  },
  {
    id: 604,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe4,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25103/25104",
    color: "#231F20/#F3B016",
    button: "Details"
  },
  {
    id: 605,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe5,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25105/25106/25107",
    color: "#231F20/#F3B016/#264C52",
    button: "Details"
  },
  {
    id: 606,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe6,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25105/25106/25107",
    color: "#231F20/#F3B016/#264C52",
    button: "Details"
  },
  {
    id: 607,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe7,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25108/25109",
    color: "#F3B016/#3E1B0C",
    button: "Details"
  },
  {
    id: 608,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe8,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25108/25109",
    color: "#F3B016/#3E1B0C",
    button: "Details"
  }, 
  {
    id: 609,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe9,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25110/25111/25112",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 610,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe10,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25110/25111/25112",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 611,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe11,
    title: "Organizer Wardrobe(5 Drawer)",
    code: "25113/25114/25115",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 612,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe12,
    title: "Popular Wardrobe(5 Drawer)",
    code: "25110/25111/25112",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 613,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe13,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25116/25117",
    color: "#231F20/#F3B016",
    button: "Details"
  },
  {
    id: 614,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe14,
    title: "Popular Wardrobe(4 Drawer)",
    code: "25116/25117",
    color: "#231F20/#F3B016",
    button: "Details"
  },
  {
    id: 615,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe15,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25118/25119/25120",
    color: "#3E1B0C/#9C1C1E/#231F20",
    button: "Details"
  },
  {
    id: 616,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe16,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25118/25119/25120",
    color: "#3E1B0C/#9C1C1E/#231F20",
    button: "Details"
  },
  {
    id: 617,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe17,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25121/25122/25123",
    color: "#3E1B0C/#264C52/#231F20",
    button: "Details"
  },
  {
    id: 618,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe18,
    title: "Popular Wardrobe(4 Drawer)",
    code: "25121/25122/25123",
    color: "#3E1B0C/#264C52/#231F20",
    button: "Details"
  },
  {
    id: 619,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe19,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25124/25125/25126",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 620,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe20,
    title: "Popular Wardrobe(4 Drawer)",
    code: "25124/25125/25126",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 621,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe21,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25127/25128/25129",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 622,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe22,
    title: "Popular Wardrobe(4 Drawer)",
    code: "25127/25128/25129",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 623,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe23,
    title: "Organizer Wardrobe(4 Drawer)",
    code: "25127/25128/25129",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 624,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe24,
    title: "Popular Wardrobe(4 Drawer)",
    code: "25127/25128/25129",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 625,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe25,
    title: "Single Or. waredrobe(5 Drawer)",
    code: "25130/25131/25132",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 626,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe26,
    title: "Single waredrobe(5 Drawer)",
    code: "25130/25131/25132",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 627,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe27,
    title: "Single Or. waredrobe(5 Drawer)",
    code: "25133/25134/25135",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 628,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe28,
    title: "Single waredrobe(5 Drawer)",
    code: "25133/25134/25135",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 629,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe29,
    title: "Single Or. waredrobe(5 Drawer)",
    code: "25136/25137/25138",
    color: "#DE8BBB/#61AB45/#9C1C1E",
    button: "Details"
  },
  {
    id: 630,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe30,
    title: "Single waredrobe(5 Drawer)",
    code: "25136/25137/25138",
    color: "#DE8BBB/#61AB45/#9C1C1E",
    button: "Details"
  },
  {
    id: 631,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe31,
    title: "Single Or. waredrobe(5 Drawer)",
    code: "25139/25140/25141",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 632,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe32,
    title: "Single waredrobe(5 Drawer)",
    code: "25136/25137/25138",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 633,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe33,
    title: "Single Or. waredrobe(4 Drawer)",
    code: "25142/25143/25144",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 634,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe34,
    title: "Single waredrobe(4 Drawer)",
    code: "25142/25143/25144",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 635,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe35,
    title: "Single Or. waredrobe(5 Drawer)",
    code: "25145/25146/25147",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 636,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe36,
    title: "Single waredrobe(4 Drawer)",
    code: "25145/25146/25147",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 637,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe37,
    title: "Single Or. waredrobe(4 Drawer)",
    code: "25148/25149/25150",
    color: "#DE8BBB/#61AB45/#9C1C1E",
    button: "Details"
  },
  {
    id: 638,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe38,
    title: "Single waredrobe(4 Drawer)",
    code: "25148/25149/25150",
    color: "#DE8BBB/#61AB45/#9C1C1E",
    button: "Details"
  },
  {
    id: 639,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe39,
    title: "Single Or. waredrobe(4 Drawer)",
    code: "25151/25152/25153",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  {
    id: 640,
    category: "PLASTIC FURNITURE",
    imgSrc: waredrobe40,
    title: "Single waredrobe(4 Drawer)",
    code: "25151/25152/25153",
    color: "#3E1B0C/#F3B016/#9C1C1E",
    button: "Details"
  },
  
  
  
  //        plastic Dinning Table
  
  
  {
    id: 801,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable1,
    title: "4 seated square table",
    code: "24201/24202/24203",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 802,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable2,
    title: "4 seated square table",
    code: "24201/24202/24203",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 803,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable3,
    title: "4 seated square table",
    code: "24204/24205/24206",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 804,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable4,
    title: "4 seated square table",
    code: "24204/24205/24206",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 805,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable5,
    title: "8 seated square Deco table",
    code: "24207/24208/24209",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 806,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable6,
    title: "8 seated square Deco table",
    code: "24207/24208/24209",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 807,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable7,
    title: "8 seated square Deco table",
    code: "24210/24211/24212",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 808,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable8,
    title: "8 seated square Deco table",
    code: "24210/24211/24212",
    color: "#F3B016/#3E1B0C/#9C1C1E",
    button: "Details"
  },
  {
    id: 809,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable9,
    title: "Baby Reading table",
    code: "24213/24214/24215",
    color: "#F3B016/#2B60AD/red",
    button: "Details"
  },
  {
    id: 810,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable10,
    title: "Baby Reading table",
    code: "24213/24214/24215",
    color: "#F3B016/#2B60AD/red",
    button: "Details"
  },
  {
    id: 811,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable11,
    title: "Restaurent table",
    code: "24216/24217/24218",
    color: "#F3B016/#996633/#9C1C1E",
    button: "Details"
  },
  {
    id: 812,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable12,
    title: "Restaurent table",
    code: "24216/24217/24218",
    color: "#F3B016/#996633/#9C1C1E",
    button: "Details"
  },
  {
    id: 813,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable13,
    title: "6 seated square table",
    code: "24219/24220/24221",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 814,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable14,
    title: "6 seated square table",
    code: "24219/24220/24221",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 815,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable15,
    title: "6 seated square table",
    code: "24222/24223/24224",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 816,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable16,
    title: "6 seated square table",
    code: "24222/24223/24224",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 817,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable17,
    title: "6 seated semi oval table",
    code: "24225/24226/24227",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 818,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable18,
    title: "6 seated semi oval table",
    code: "24225/24226/24227",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 819,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable19,
    title: "6 seated semi oval table",
    code: "24228/24229/24230",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 820,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable20,
    title: "6 seated semi oval table",
    code: "24228/24229/24230",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 821,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable21,
    title: "6 seated oval table",
    code: "25231/25232/25233",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 822,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable22,
    title: "6 seated oval table",
    code: "25234/25235/25236",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 823,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable23,
    title: "T Classic table",
    code: "25240/25241/25242",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 824,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable24,
    title: "T Classic table",
    code: "25240/25241/25242",
    color: "#F3B016/#000/#9C1C1E",
    button: "Details"
  },
  {
    id: 825,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable25,
    title: "Coffee table",
    code: "25245/25246/25247",
    color: "#F3B016/#ac7339/#9C1C1E",
    button: "Details"
  },
  {
    id: 826,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable26,
    title: "Coffee table",
    code: "25245/25246/25247",
    color: "#F3B016/#ac7339/#9C1C1E",
    button: "Details"
  },
  {
    id: 827,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable27,
    title: "4 seated round table",
    code: "24126/24127/24128/24129/24130",
    color: "red/#003d99/#9C1C1E/#F3B016/#00cc99",
    button: "Details"
  },
  {
    id: 828,
    category: "PLASTIC FURNITURE",
    imgSrc: dTable28,
    title: "4 seated round table",
    code: "24126/24127/24128/24129/24130",
    color: "red/#003d99/#9C1C1E/#F3B016/#00cc99",
    button: "Details"
  },

  
  
    //                          plastic  chair
    {
        id: 1301,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair1,
        title: "King Chair Flower",
        code: "21141/21142/21143/21144/21145/21146",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1302,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair2,
        title: "King Chair Garden",
        code: "21401/21402/21403/21404/21405/21406",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1303,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair3,
        title: "King Chair Baton",
        code: "21410/21411/21412/21413/21414/21415",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1304,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair4,
        title: "Relax Chair Design",
        code: "21171/21172/21173/21174/21175/21176",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1305,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair5,
        title: "Relax Chair Design",
        code: "21171/21172/21173/21174/21175/21176",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1306,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair6,
        title: "Relax Chair Baton",
        code: "21161/21162/21163/21164/21165/21166",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1307,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair7,
        title: "Crown Chair Baton",
        code: "21251/21252/21253/21254/21255/21256",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1308,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair8,
        title: "Crown Chair Design",
        code: "21231/21232/21233/21234/21235/21236",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1309,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair9,
        title: "Garden Chair Flower",
        code: "21301/21302/21303/21304/21305/21306",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1310,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair10,
        title: "Garden Chair Stick",
        code: "21321/21322/21323/21324/21325/21326",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1311,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair11,
        title: "Garden Chair E-mat",
        code: "21331/21332/21333/21334/21335/21336",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"   
      },                       
      {      
        id: 1312,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair12,
        title: "Garden Chair Mat Flower",
        code: "21341/21342/21343/21344/21345/21346",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1313,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair13,
        title: "Baby Chair Design",
        code: "22101/22102",
        color: "#EF4443/#2B60AD",
        button: "Details"
      },
      {
        id: 1314,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair14,
        title: "Baby Chair Flower",
        code: "22105/22106",
        color: "#EF4443/#2B60AD",
        button: "Details"   
      },                       
      {      
        id: 1315,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair15,
        title: "Baby Chair Stick",
        code: "22111/22112",
        color: "#EF4443/#2B60AD",
        button: "Details"
      },
      
      {
        id: 1316,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair16,
        title: "Baby Chair Solid",
        code: "22115/22116",
        color: "#EF4443/#2B60AD",
        button: "Details"
      },
      {
        id: 1317,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair17,
        title: "Deco Chair Flower",
        code: "21401/21402/21403/21404/21405/21406",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"   
      },                       
      {      
        id: 1318,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair18,
        title: "Deco Chair Ring",
        code: "21411/21412/21413/21414/21415/21416",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {      
        id: 1319,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair19,
        title: "Deco Chair Stick",
        code: "21421/21422/21423/21424/21425/21426",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {
        id: 1320,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair20,
        title: "Deco Chair E-mat",
        code: "21431/21432/21433/21434/21435/21436",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"   
      },   
                        
      {      
        id: 1321,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair21,
        title: "Dining Chair Design",
        code: "21201/21202/21203/21204/21205/21206",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
      {      
        id: 1322,
        category: "PLASTIC FURNITURE",
        imgSrc: pchair22,
        title: "Dining Chair Stick",
        code: "21211/21212/21213/21214/21215/21216",
        color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
        button: "Details"
      },
        {
          id: 1323,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair23,
          title: "Dining Chair E-mat",
          code: "21221/21222/21223/21224/21225/21226",
          color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
          button: "Details"   
        },   
                          
        {      
          id: 1324,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair24,
          title: "Dining Chair Design",
          code: "21231/21232/21233/21234/21235/21236",
          color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
          button: "Details"
        },
        {      
          id: 1325,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair25,
          title: "President Chair Super",
          code: "21151/21152/21153/21154/21155/21156",
          color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
          button: "Details"
        },
        {
          id: 1326,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair26,
          title: "President Chair Classic",
          code: "21161/21162/21163/21164/21165/21166",
          color: "#EF4443/#2B60AD/#0C8862/#6A3A3A/#F9A156/#0F7079",
          button: "Details"   
        },                  
        {      
          id: 1327,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair27,
          title: "Teachers Chair",
          code: "21191/21192/21193/21194",
          color: "#EF4443/#2B60AD/#000/#F9A156",
          button: "Details"
        },
        {      
          id: 1328,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair28,
          title: "Teachers Chair",
          code: "21191/21192/21193/21194",
          color: "#EF4443/#2B60AD/#000/#F9A156",
          button: "Details"
        },
        {
          id: 1329,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair29,
          title: "Waiting Chair 2 Seated",
          code: "27101",
          color: "Any Color",
          button: "Details"   
        },   
                          
        {      
          id: 1330,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair30,
          title: "Waiting Chair 3 Seated",
          code: "27102",
          color: "Any Color",
          button: "Details"
        },
        {      
          id: 1331,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair31,
          title: "Waiting Chair 3 Seated (with arm)",
          code: "27103",
          color: "Any Color",
          button: "Details"
        },
        {
          id: 1332,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair32,
          title: "Waiting Chair 3 Seated",
          code: "27104",
          color: 'Any Color',
          button: "Details"   
        },   
                          
        {      
          id: 1333,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair33,
          title: "Class Room Chair",
          code: "27110",
          color: "#000",
          button: "Details"
        },
        {      
          id: 1334,
          category: "PLASTIC FURNITURE",
          imgSrc: pchair34,
          title: "Visitor Chair",
          code: "27111/27112/27113",
          color: "#000/#2B60AD/#EF4443",
          button: "Details"
        },
  
   //           plastic household    
   //                   upcoming products
  
   {
    id: 1601,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming1,
    title: "Planter",
    code: "29010/29011/29012/29013/29014/29015/29016/29017/29018",
    color: "#BC4A3C/white/white/#A1662F/white/white/black/white/white",
    size: `16", 18", 21"`,
    button: "Details"
   }, 
   {
    id: 1602,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming2,
    title: "Planter",
    code: "29019/29020/29021/29022/29023/29024/29025/29026/29027",
    color: "#BC4A3C/white/#white/#A1662F/white/white/black/white/white",
    size: `11", 12", 14"`,
    button: "Details"
   },
   {
    id: 1603,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming3,
    title: "Planter",
    code: "29028/29029/29030/29031/29032/29033/29034/29035/29036",
    color: "#BC4A3C/white/white/#A1662F/#white/white/black/white/white",
    size: `7", 8", 10"`,
    button: "Details"
   },
   {
    id: 1604,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming4,
    title: "Planter",
    code: "29037/29038/29039/29040/29041/29042/29043/29044/29045",
    color: "#BC4A3C/white/white/#A1662F/white/white/black/white/white",
    size: `3", 4.5", 6.5"`,
    button: "Details"
   },
   {
    id: 1605,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming5,
    title: "Flower Crystal Jug",
    code: "29120/29121/29122/29123",
    color: "pink/white/green/orange",
    size: `2.5 Ltr.`,
    button: "Details"
   },
   {
    id: 1606,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming6,
    title: "Crown Crystal Jug",
    code: "29124/29125/29126/29127",
    color: "pink/white/green/orange",
    size: `2 Ltr.`,
    button: "Details"
   },
   {
    id: 1607,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming7,
    title: "Tulip Jug",
    code: "29130/29131/29132",
    color: "pink/red/green",
    size: `3.5 Ltr.`,
    button: "Details"
   },
   {
    id: 1608,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming8,
    title: "Folding Baby Hanger",
    code: "29140/29141/29142/29143",
    color: "pink/blue/red/paste",
    button: "Details"
   },
   {
    id: 1609,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming9,
    title: "Round Baby Hanger",
    code: "29151/29152/29153/29154",
    color: "pink/blue/red/paste",
    button: "Details"
   },
   {
    id: 1610,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming10,
    title: "Flower Baby Hanger",
    code: "29155/29156/29157/29158",
    color: "pink/blue/red/paste",
    button: "Details"
   },
   {
    id: 1611,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming11,
    title: "Round Paddle Bin",
    code: "29160/29161/29162/29163/29164/29165",
    color: "black/blue/red/black/blue/red",
    size: `15 Ltr., 20 Ltr.`,
    button: "Details"
   },
   {
    id: 1612,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming12,
    title: "Square Paddle Bin",
    code: "29170/29171/29172/29173/29174/29175",
    color: "black/blue/red/black/blue/red",
    size: `15 Ltr., 20 Ltr.`,
    button: "Details"
   },
   {
    id: 1613,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming13,
    title: "Picnic Basket",
    code: "29180/29181/29182/29183",
    color: "#efcca2/red/blue/pink",
    button: "Details"
   },
   {
    id: 1614,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming14,
    title: "Smart Wall Hanger",
    code: "29181/29182/29183",
    color: "red/blue/pink",
    button: "Details"
   },
   {
    id: 1615,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming15,
    title: "Chair Baby Potty",
    code: "29184/29185/29186",
    color: "blue/red/pink",
    size: `Regular`,
    button: "Details"
   },
   {
    id: 1616,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: plasticUpcoming16,
    title: "Kids Reading Table",
    code: "29190/29191/29192/29193",
    color: "pink/red/blue/green",
    button: "Details"
   },
  
    //                     Household items
  
    {
      id: 1801,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold1,
      title: "Noodles Net",
      code: "12106/12107/12108",
      color: "#4A7E8B/yellow/red",
      button: "Details"
    },
    {
      id: 1802,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold2,
      title: "Vegetable Washing Net",
      code: "17095/17101/17096/17102",
      color: "red/white/blue/white",
      size: "32cm, 36cm",
      button: "Details"
    },
    {
      id: 1803,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold3,
      title: "Egg Box",
      code: "16205/16206",
      color: "brown/blue",
      button: "Details"
    },
    {
      id: 1804,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold4,
      title: "Chopping Board",
      code: "17061",
      color: "white",
      button: "Details"
    },
    {
      id: 1805,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold5,
      title: "Square Rack(Delux)",
      code: "16216/16217",
      color: "red/blue",
      size: "Square",
      button: "Details"
    },
    {
      id: 1806,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold6,
      title: "Square Rack",
      code: "16201/16202/16203",
      color: "red/blue/orange",
      size: "Square",
      button: "Details"
    },
    {
      id: 1807,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold7,
      title: "Shoe Rack",
      code: "16205/16206",
      color: "brown/blue",
      button: "Details"
    },
    {
      id: 1808,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold8,
      title: "Oval Rack",
      code: "16212/16213/16214",
      color: "red/blue/orange",
      size: "Oval",
      button: "Details"
    },
    {
      id: 1809,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold9,
      title: "Spicy Tray(Multi)",
      code: "17034/17035/17036/17037/17038/17039",
      color: "red/blue/pink/yellow/white/#6a5c3c",
      button: "Details"
    },
    {
      id: 1810,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold10,
      title: "Spicy Tray",
      code: "17031",
      color: "red",
      button: "Details"
    },
    {
      id: 1811,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold11,
      title: "Delux Bowl",
      code: "12165/12166/12168/12169/12178/12172/12175/12176/12180/12181/12185/12186/12188/12189/12191/12192/12195/12196",
      color: "red/white/white/white/white/white/white/white/blue/white/white/white/white/white/white/white/white/white",
      size: "3 Ltr, 5 Ltr, 8Ltr, 10Ltr, 15Ltr, 20 Ltr, 25 Ltr, 30 Ltr, 35 Ltr",
      button: "Details"
    },
    {
      id: 1812,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold12,
      title: "Design Bowl",
      code: "12118/12119/12131/12132/12141/12142/12151/12152/12161/12162",
      color: "red/white/white/white/white/white/white/white/blue/white",
      size: "3 Ltr, 8 Ltr, 15 Ltr, 20 Ltr, 25 Ltr",
      button: "Details"
    },
    {
      id: 1813,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold13,
      title: "Design Bucket With Lid",
      code: "11106/11108/11111/11117/11121/11126/11131",
      color: "blue/white/white/white/white/white/white",
      size: "5 Ltr, 8 Ltr, 10 Ltr, 16 Ltr, 20 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1814,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold14,
      title: "Design Bucket With Lid",
      code: "11105/11107/11110/11116/11120/11120/11130",
      color: "red/white/white/white/white/white/white",
      size: "5 Ltr, 8 Ltr, 10 Ltr, 16 Ltr, 20 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1815,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold15,
      title: "Design Bucket",
      code: "11306/11308/11311/11317/11321/11326/11331",
      color: "blue/white/white/white/white/white/white",
      size: "5 Ltr, 8 Ltr, 10 Ltr, 16 Ltr, 20 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1816,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold16,
      title: "Design Bucket",
      code: "11305/11307/11310/11316/11320/11325/11330",
      color: "red/white/white/white/white/white/white",
      size: "5 Ltr, 8 Ltr, 10 Ltr, 16 Ltr, 20 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1817,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold17,
      title: "Delux Bucket",
      code: "11140/11146/11149/11152/11156/11161/11169",
      color: "red/white/white/white/white/white/white",
      size: "5 Ltr, 8 Ltr, 10 Ltr, 16 Ltr, 20 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1818,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold18,
      title: "Delux Bucket",
      code: "11141/11147/11150/11153/11157/11162/11170",
      color: "blue/white/white/white/white/white/white",
      size: "8 Ltr, 12 Ltr, 15 Ltr, 20 Ltr, 22 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1819,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold19,
      title: "Delux Bucket",
      code: "11171/11172/11173/11174/11175/11176/11177",
      color: "black/#fff/#fff/#fff/#fff/#fff/#fff",
      size: "8 Ltr, 12 Ltr, 15 Ltr, 20 Ltr, 22 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1820,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold20,
      title: "Delux Bucket",
      code: "11180/11181/11182/11183/11184/11185/11186",
      color: "#446BAD/#fff/#fff/#fff/#fff/#fff/#fff",
      size: "8 Ltr, 12 Ltr, 15 Ltr, 20 Ltr, 22 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1821,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold21,
      title: "Delux Bucket Transparent",
      code: "11190/11191/11192/11193/11194/11195/11196",
      color: "#EAB8CA/#fff/#fff/#fff/#fff/#fff/#fff",
      size: "8 Ltr, 12 Ltr, 15 Ltr, 20 Ltr, 22 Ltr, 25 Ltr, 30 Ltr",
      button: "Details"
    },
    {
      id: 1822,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold22,
      title: "Drum Bucket",
      code: "11445/11446/11460/11461",
      color: "red/blue/white/white",
      size: "45 Ltr, 60 Ltr",
      button: "Details"
    },
    {
      id: 1823,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold23,
      title: "Drum Bucket With Clip",
      code: "11465/11466",
      color: "red/blue",
      size: "75 Ltr",
      button: "Details"
    },
    {
      id: 1824,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold24,
      title: "Twist Bucket(Plastic Handle)",
      code: "11385/11386/11387/11391/11392/11393",
      color: "red/blue/#4A7E8B/#fff/#fff/#fff",
      size: "16 Ltr, 20 Ltr",
      button: "Details"
    },
    {
      id: 1825,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold25,
      title: "Spout Bucket(Steel & Plastic Handle)",
      code: "11350/11351/11356/11357",
      color: "red/blue/white/white",
      size: "16 Ltr, 20 Ltr",
      button: "Details"
    },
    // {
    //   id: 1826,
    //   category: "PLASTIC HOUSEHOLD",
    //   imgSrc: plastichousehold25,
    //   title: "Washing Bowl",
    //   code: "",
    //   color: "",
    //   button: "Details"
    // },
    {
      id: 1827,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold27,
      title: "Bathroom Pot",
      code: "17065/17070",
      color: "blue/white",
      size: "2 Ltr, 2.25 Ltr",
      button: "Details"
    },
    {
      id: 1828,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold28,
      title: "Design Mug",
      code: "14201/14202/14204/14205/14207/14208",
      color: "red/blue/white/white/white/white",
      size: "0.5 Ltr, 1.5 Ltr, 2 Ltr",
      button: "Details"
    },
    {
      id: 1829,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold29,
      title: "Baby Potty",
      code: "1701",
      color: "red",
      button: "Details"
    },
    {
      id: 1830,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold30,
      title: "Bath Tab",
      code: "17051/17052",
      color: "red/white",
      button: "Details"
    },
    {
      id: 1831,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold31,
      title: "Dust Pan",
      code: "17041/17042",
      color: "red/blue",
      button: "Details"
    },
    {
      id: 1832,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold32,
      title: "Classic Dust Pan",
      code: "17044/17045",
      color: "#4A7E8B/blue",
      button: "Details"
    },
    {
      id: 1833,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold33,
      title: "Rectangular Soap Case",
      code: "17160/17161",
      color: "pink/yellow",
      size: "Rectangular",
      button: "Details"
    },
    {
      id: 1834,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold34,
      title: "Oval Soap Case",
      code: "17155/17156",
      color: "pink/yellow",
      size: "Oval",
      button: "Details"
    },
    {
      id: 1835,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold35,
      title: "Beauty Soap Case",
      code: "17065/17070",
      color: "pink/yellow",
      button: "Details"
    },
    {
      id: 1836,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold36,
      title: "Wastage Paper Basket",
      code: "15201/15202",
      color: "red/blue",
      button: "Details"
    },
    {
      id: 1837,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold37,
      title: "Laundry Basket",
      code: "15206/15207/15208",
      color: "red/blue/yellow",
      button: "Details"
    },  
    { 
      id: 1838,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold38,
      title: "Shirt Hanger",
      code: "17022/17023/17024",
      color: "#6a5c3c/red/white",
      button: "Details"
    }, 
    { 
      id: 1839,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold39,
      title: "Coat Hanger",
      code: "17020/17021/17022",
      color: "red/blue/#6a5c3c",
      button: "Details"
    },
    {
      id: 1840,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold40,
      title: "Wall Hanger",
      code: "17003/17004",
      color: "#6a5c3c/white",
      button: "Details"
    }, 
    {
      id: 1841,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold41,
      title: "Dish Cover",
      code: "17081/17082/17084/17085/17087/17088",
      color: "red/white/white/white/white/white",
      size: "26 mm, 32 mm, 36 mm",
      button: "Details"
    }, 
    { 
      id: 1842,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold42,
      title: "Dish Rack",
      code: "16209/16210/16211",
      color: "red/blue/orange",
      button: "Details"
    },
    {
      id: 1843,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold43,
      title: "Handle Mug",
      code: "14226/14227/14228",
      color: "red/#12279e/#4A7E8B",
      button: "Details"
    },
    {
      id: 1844,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold44,
      title: "Coffee and Beverage Mug",
      code: "14210/14211/14214/14215/14216",
      color: "#4A7E8B/#6f4e37/#4A7E8B/pink/yellow",
      button: "Details"
    },
    {
      id: 1845,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold45,
      title: "Drinking Glass",
      code: "14222/14223/14224",
      color: "#90EE90/pink/#fff",
      button: "Details"
    },
    {
      id: 1846,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold46,
      title: "Dispossible Plate",
      code: "17150/17151/17152",
      color: "#90EE90/#4A7E8B/#FFFEF7",
      button: "Details"
    },
    {
      id: 1847,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold47,
      title: "Transparent Design Bati",
      code: "12103/12104",
      color: "pink/blue",
      button: "Details"
    },
    {
      id: 1848,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold48,
      title: "Design Bati",
      code: "12110/12111",
      color: "red/blue",
      button: "Details"
    },
    {
      id: 1849,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold49,
      title: "Transparent Design Bati",
      code: "12114/12115",
      color: "pink/blue",
      size: "1500 ml",
      button: "Details"
    },
    {
      id: 1850,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold50,
      title: "Curry Bowl",
      code: "17100/17101",
      color: "#ffffff/pink",
      button: "Details"
    },
    // {
    //   id: 1851,
    //   category: "PLASTIC HOUSEHOLD",
    //   imgSrc: plastichousehold51,
    //   title: "Smaile Basket",
    //   button: "Details"
    // },
    // {
    //   id: 1852,
    //   category: "PLASTIC HOUSEHOLD",
    //   imgSrc: plastichousehold52,
    //   title: "Small Tray",
    //   button: "Details"
    // },
    {
      id: 1853,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold53,
      title: "Tiffin Box",
      code: "17011/17012",
      color: "pink/blue",
      button: "Details"
    },
    {
      id: 1854,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold54,
      title: "Design Jug(Close Handle)",
      code: "13108/13109/13111/13112",
      color: "red/blue/pink/blue",
      size: "3 Ltr",
      button: "Details"
    },
    {
      id: 1855,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold55,
      title: "Design Jug(Open Handle)",
      code: "13101/13102/13105/13106",
      color: "red/blue/white/white",
      size: "2.5 Ltr, 3Ltr",
      button: "Details"
    },
    {
      id: 1856,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold56,
      title: "Design Jug(Close Handle)",
      code: "13115/13116",
      color: "red/blue",
      size: "3 Ltr",
      button: "Details"
    },
    {
      id: 1857,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold57,
      title: "Printed Jug(Cosmos)",
      code: "17181/17182/17183",
      color: "pink/blue/green",
      size: "3 Ltr.",
      button: "Details"
    },
    {
      id: 1858,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold58,
      title: "Printed Jug(Camellia)",
      code: "17185/17186/17187",
      color: "pink/blue/green",
      size: "3 Ltr.",
      button: "Details"
    },
    {
      id: 1859,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold59,
      title: "Printed Jug(Vintage)",
      code: "17190/17191/17192",
      color: "pink/blue/green",
      size: "3 Ltr.",
      button: "Details"
    },
    {
      id: 1860,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold60,
      title: "Square Container set",
      code: "17134",
      color: "#fff",
      size: "700ml, 1500ml, 2200ml",
      button: "Details"
    },
    {
      id: 1861,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold61,
      title: "Grocery Pan",
      code: "14218/14219",
      color: "#4A7E8B/blue",
      button: "Details"
    },
    {
      id: 1862,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold62,
      title: "Paper Tray",
      code: "15210/15211",
      color: "choclate/gray",
      button: "Details"
    },
    // {
    //   id: 1863,
    //   category: "PLASTIC HOUSEHOLD",
    //   imgSrc: plastichousehold63,
    //   title: "Cabinet",
    //   button: "Details"
    // },
    {
      id: 1864,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold64,
      title: "Round Lock Container",
      code: "17144",
      color: "#fff",
      size: "1800ml, 2800ml,4200ml",
      button: "Details"
    },
    {
      id: 1865,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold65,
      title: "Reactangular Container Set",
      code: "17124",
      color: "#fff",
      size: "100ml, 2000ml, 3000ml",
      button: "Details"
    },
    {
      id: 1866,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold66,
      title: "Square Container Set",
      code: "17134",
      color: "#fff",
      size: "700ml, 1500ml, 2200ml",
      button: "Details"
    },
    {
      id: 1867,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold67,
      title: "Storage Container",
      code: "17181/17182",
      color: "pink/blue",
      size: "3 Ltr, 5 Ltr, 7 Ltr, 10 Ltr",
      button: "Details"
    },
    {
      id: 1868,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold68,
      title: "Printed Storage Container",
      code: "17191/17192",
      color: "red/pink",
      size: "3 Ltr, 5 Ltr, 7 Ltr, 10 Ltr",
      button: "Details"
    },
    {
      id: 1869,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold69,
      title: "Printed Storage Container",
      code: "17194/17195",
      color: "red/pink",
      size: "3 Ltr, 5 Ltr, 7 Ltr, 10 Ltr",
      button: "Details"
    },
    {
      id: 1870,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold70,
      title: "Printed Storage Container",
      code: "17196",
      color: "green",
      size: "3 Ltr, 5 Ltr, 7 Ltr, 10 Ltr",
      button: "Details"
    },
    {
      id: 1871,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold71,
      title: "Printed Storage Container",
      code: "17197/17198",
      color: "red/pink",
      size: "15 Ltr",
      button: "Details"
    },
    {
      id: 1872,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold72,
      title: "Fish Crate",
      code: "15250/15251/15252",
      color: "red/blue/orange",
      button: "Details"
    },
    {
      id: 1873,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold73,
      title: "High Net Stool",
      code: "23301/23302",
      color: "red/blue",
      size: "High",
      button: "Details"
    },
    {
      id: 1874,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold74,
      title: "Medium Net Stool",
      code: "23201/23202",
      color: "red/blue",
      size: "Medium",
      button: "Details"
    },
    {
      id: 1875,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold75,
      title: "Short Stool",
      code: "23101/23102",
      color: "red/blue",
      size: "Short",
      button: "Details"
    },
    {
      id: 1876,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: plastichousehold76,
      title: "Magic Stool",
      code: "23313/23213",
      size: "High/Medium",
      button: "Details"
    },
  
  //                               plastic household
  
   {
    id: 1700,
    category: "PLASTIC HOUSEHOLD",
    imgSrc: kitchenalmirah1,
    title: "Kitchen Almirah",
    code: "30101/30102/30103/30104/30105/30106",
    color: "blue/white/white/pink/white/white",
    button: "Details"
   },
  
  //                                kitchen rack 
  
    {
      id: 1701,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack2,
      title: "Kitchen Rack (3 side Barrier)",
      code: "26101/26102/26103/26104",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1702,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack1,
      title: "Kitchen Rack (without tray)",
      code: "26115/26116/26117/26118",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1703,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack3,
      title: "Kitchen Rack (3 side Barrier)",
      code: "26101/26102/26103/26104",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1704,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack4,
      title: "Kitchen Rack (with tray)",
      code: "26110/26111/26112/26113",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1705,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack5,
      title: "Kitchen Rack (3 side Barrier)",
      code: "26101/26102/26103/26104",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1706,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack6,
      title: "Kitchen Rack (with tray)",
      code: "26110/26111/26112/26113",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91",
      button: "Details"
    },
    {
      id: 1707,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack7,
      title: "Kitchen Rack (3 side Barrier)",
      code: "26120/26121/26122/26123/26124",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91/#656AB1",
      button: "Details"
    },
    {
      id: 1708,
      category: "PLASTIC HOUSEHOLD",
      imgSrc: kitchenrack8,
      title: "Kitchen Rack (Book Shelf)",
      code: "26125/26126/26127/26128/26129",
      color: "#C29B2E/#E1352A/#8FD7ED/#dd2C91/#656AB1",
      button: "Details"
    },

    
                                               //      Toys (4001-4100)
  {
    id: 4001,
    category: "PLASTIC TOY",
    imgSrc: toy1,
    title: "Master Bike",
    code: "28201/28202/28203/28204/28205/28206",
    color: "#B62371/#D67B50/#37499E/#CA9E2C/#59A3B8/#D62128",
    button: "Details"
  },
  {
    id: 4002,
    category: "PLASTIC TOY",
    imgSrc: toy2,
    title: "Master Bike with Barrier",
    code: "28207/28208/28209/28210/28211/28212",
    color: "#B62371/#D67B50/#37499E/#CA9E2C/#59A3B8/#D62128",
    button: "Details"
  },
  {
    id: 4003,
    category: "PLASTIC TOY",
    imgSrc: toy3,
    title: "Master Bike with Trolly",
    code: "28213/28214/28215/28216/28217/28218",
    color: "#B62371/#D67B50/#37499E/#CA9E2C/#59A3B8/#D62128",
    button: "Details"
  },
  {
    id: 4004,
    category: "PLASTIC TOY",
    imgSrc: toy4,
    title: "Master Bike with Umbrella",
    code: "28219/28220/28221/28222/28223/28224",
    color: "#B62371/#D67B50/#37499E/#CA9E2C/#59A3B8/#D62128",
    button: "Details"
  },
  {
    id: 4005,
    category: "PLASTIC TOY",
    imgSrc: toy5,
    title: "Master Bike with Trolly",
    code: "28210",
    color: "#CA9E2C",
    button: "Details"
  },
  {
    id: 4006,
    category: "PLASTIC TOY",
    imgSrc: toy6,
    title: "Master Bike",
    code: "28211",
    color: "#59A3B8",
    button: "Details"
  },
  {
    id: 4007,
    category: "PLASTIC TOY",
    imgSrc: toy7,
    title: "Master Bike with Trolly",
    code: "28130",
    color: "#C2D065",
    button: "Details"
  },
  {
    id: 4008,
    category: "PLASTIC TOY",
    imgSrc: toy8,
    title: "Master Bike with Trolly",
    code: "28131",
    color: "#497774",
    button: "Details"
  },

  
  
    //                                    plastic table series
  
  
      ///                upvc

      
   //                                        upvc

  {
    id: 1001,
    category: "NON PRESSURE PIPE",
    title: "Buried Pipe",
    imgSrc: upvc1,
    button: "Details",
    longCode: "31806/31807/31808/31809/31810/31811",
    longSize: '110mm/160mm/200mm/250mm/315mm/400mm',
    longThickness: `3.1(Customize)/3.1(Customize)/3.2(Customize)/3.9(Customize)/4.9(Customize)/6.3(Customize)`,
    longLength: `6mtr/6mtr/6mtr/6mtr/6mtr/6mtr`,
    longColor: `Gray(Customize)/Gray(Customize)/Gray(Customize)/Gray(Customize)/Gray(Customize)/Gray(Customize)`
  },
  {
    id: 1002,
    category: "PRESSURE PIPE",
    title: "B Class Pipe",
    imgSrc: upvc2,
    button: "Details",
    longCode: "31301/31305/31401/31501/31605/31801/32100/32120/32140/32160",
    longSize: `3"/3"/4"/5"/6"/8"/10"/12"/14"/16"`,
    longThickness: `2.9~3.4/2.9~3.4/3.4~4.0/3.8~4.4/4.5~5.2/5.3~6.1/6.6~7.6/7.8~9.0/8.5~9.8/9.7~11.2`,
    longLength: `6mtr/3mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr`,
    longColor: `Gray/white/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
  },
  {
    id: 1003,
    category: "PRESSURE PIPE",
    imgSrc: upvc3,
    title: "C Class Pipe",
    button: "Details",
    longCode: "31201/31202/31210/31213/31405/31505/31610/31805/31806/32105/32125/32126/32145/32165",
    longSize: `2"/2"/3"/3"/4"/5"/6"/8"/8"/10"/12"/12"/14"/16"`,
    longThickness: `2.5~3.0/2.5~3.0/3.5~4.1/3.5~4.1/4.5~5.2/5.5~6.4/6.6~7.6/7.8~9.0/7.8~9.0/9.7~11.2/11.5~13.3/11.5~13.3/12.6~14.5/14.5~16.7`,
    longLength: `15ft/10ft/6ft/3mtr/6mtr/6mtr/6mtr/6mtr/3mtr/6mtr/6mtr/3mtr/6mtr/6mtr`,
    longColor: `Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
  },
  {
    id: 1004,
    category: "PRESSURE PIPE",
    imgSrc: upvc3,
    title: "D Class Pipe",
    button: "Details",
    longCode: "31150/31151/31205/31315/31410/31507/31615/31810/32129/32148/32168/31125",
    longSize: `1.5"/1.5"/2"/3"/4"/5"/6"/8"/12"/14"/16"/1.25"`,
    longThickness: `2.5~3.0/2.5~3.0/3.1~3.7/4.6~5.3/6.0~6.9/7.3~8.0/8.8~10.2/10.2~11.9/15.2~17.5/16.7~19.2/19.0~21.9/2.2~2.7`,
    longLength: `15ft/15ft/15ft/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/10ft`,
    longColor: `Gray/white/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
  },
  {
    id: 1005,
    category: "PRESSURE PIPE",
    imgSrc: upvc3,
    title: "E Class Pipe",
    button: "Details",
    longCode: "31210/31320/31415/31620/31815/32132/32150/31010/31013/31125",
    longSize: `2"/3"/4"/6"/8"/12"/14"/0.5"/0.75"/1.25"`,
    longThickness: `3.9~4.5/5.7~6.6/7.3~8.4/10.8~12.5/12.6~14.5/18.6~21.7/20.5~23.5/1.7~2.1/1.9~2.3/2.2~2.7`,
    longLength: `15ft/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/10ft/10ft/10ft`,
    longColor: `Gray/white/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
  },
  {
    id: 1006,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc6,
    title: "Gold Series Pipe",
    button: "Details",
    longCode: "36340/36341/36452/36453/36455/36456/36458/36459/36540/31425/31426/31542/36843",
    longThickness: `2.2~2.5/2.2~2.5/2.8~3.1/2.8~3.1/2.2~2.5/2.2~2.5/1.9~2.0/1.9~2.0/3.2~3.5/2.8~3.0/2.7/3.5/2.8~3.0 `,
    longSize: `3"/3"/110mm/110mm/110mm/110mm/110mm/110mm/5"/4"/4"/5"/200mm`,
    longLength: `6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr`,
    longColor: `Gray/white/Gray/white/Gray/white/Gray/Gray/Gray/Gray/white/Gray/Gray`
  },
  {
    id: 1007,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc7,
    title: "Gold Series Filter Pipe",
    button: "Details",
    longCode: "37315/37316/37320/37432/37433/37434/37435/30424/30429/37509/37511",
    longThickness: `2.2~2.5/2.2~2.5/2.9/2.8~3.1/2.8~3.1/2.2~2.5/2.2~2.5/2.8~3.0/3.4/3.2~3.5 `,
    longSize: `3"/3"/3"/110mm/110mm/110mm/110mm/4"/4"/5"/5"`,
    longLength: `3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/3mtr`,
    longColor: `Gray/white/Gray/Gray/white/Gray/white/Gray/Gray/Gray/Gray`
  },
  {
    id: 1008,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc8,
    title: "Standard Pipe",
    button: "Details",
    longCode: "31156/31157/31160/31161/31165/31166/31175/36459/36540/31425/31426",
    longThickness: `2.2~2.5/2.2~2.5/2.0~2.3/2.0~2.3/1.4~1.6/1.4~1.6/1.4~1.6/2.0~2.2/2.0~2.2/2.5~2.7/2.8~3.1 `,
    longSize: `1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/3"/3"`,
    longLength: `15mtr/15mtr/15mtr/15mtr/15mtr/15mtr/15mtr/6mtr/15mtr/6mtr/6mtr`,
    longColor: `Gray/white/Gray/white/Gray/white/Firoza/Firoza/Firoza/Firoza/Gray`
  },
  {
    id: 1009,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc9,
    title: "Sanitary Pipe",
    button: "Details",
    longCode: "34125/34126/34123/34124/34131/34132/34133/34184/34185/34211/34212",
    longThickness: `1.6~1.8/1.6~1.8/1.6~1.8/1.6~1.8/1.6~1.8/1.6~1.8/1.6~1.8/2.7~30/2.7~30/3.0~34/3.0~34`,
    longSize: `82mm/82mm/86mm/86mm/110mm/110mm/110mm/160mm/160mm/200mm/200mm`,
    longLength: `6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr`,
    longColor: `Gray/white/Gray/white/Gray/white/Firoza/Gray/White/Gray/White`
  },
  {
    id: 1010,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc10,
    title: "Uttom Pipe",
    button: "Details",
    longCode: "31170/31171/31177/31176/31178",
    longThickness: `2.7/2.7~3.0/3.0~3.4/2.7~3.0/3.0~3.4`,
    longSize: `110mm/110mm/110mm/110mm/110mm`,
    longLength: `6mtr/6mtr/6mtr/3mtr/3mtr`,
    longColor: `Gray/Gray/Gray/Gray/Gray`
  },
  {
    id: 1011,
    category: "NON PRESSURE PIPE",
    imgSrc: upvc11, 
    title: "Oring Pipe",
    button: "Details",
  },
  { 
      id: 1012,
      category: "SWR PIPE",
      imgSrc: upvc12,
      title: "swr pipe",
      button: "Details",
      longCode: "34100/34101/34111/34112/34121/34122/34151/34152/34161/34162/34171/34172/34181/34182/34186/34187/34191/34192/34200/34201/34221/34222/34231/34232",
      longSize: `32mm/32mm/40mm/40mm/50mm/50mm/100mm/100mm/110mm/110mm/110mm/110mm/110mm/110mm/160mm/160mm/160mm/160mm/160mm/160mm/200mm/200mm/200mm/200mm`,
      longThickness: `1.6~1.9/1.6~1.9/1.6~1.9/1.6~1.9/2.0~2.4/2.0~2.4/2.4~2.7/2.4~2.7/2.7~3.0/2.7~3.0/3.0~3.4/3.0~3.4/3.4~3.8/3.4~3.8/3.0~3.4/3.0~3.4/4.0~4.5/4.0~4.5/4.5~5.0/4.5~5.0/4.0~4.5/4.0~4.5/4.5~5.0/4.5~5.0`,
      longLength: `3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr/6mtr`,
      longColor: `Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White/Gray/White`
    },
    {
      id: 1013,
      category: "PLUMBING PIPE",
      imgSrc: upvc13,
      title: "Thread Pipe",
      button: "Details",
      longCode: "31019/31020/31022/31023/31025/31026/31028/31029/31031/31032/31034/31035",
      longThickness: `3.1~3.4/3.1~3.4/3.2~3.5/3.2~3.5/3.8~4.2/3.8~4.2/4.2~4.5/4.2~4.5/4.0~4.4/4.0~4.4/4.4~4.8/4.4~4.8`,
      longSize: `0.5"/0.5"/0.75"/0.75"/1"/1"/1.25"/1.25"/1.5"/1.5"/2"/2"`,
      longLength: `10ft/10ft/10ft/10ft/10ft/10ft/10ft/10ft/10ft/10ft/10ft/10ft`,
      longColor: `Gray/Green/Gray/Green/Gray/Green/Gray/Green/Gray/Green/Gray/Green`
    },
    {
      id: 1014,
      category: "PRESSURE PIPE",
      imgSrc: upvc14,
      title: "Class B Filter Pipe",
      button: "Details",
      longCode: "30310/30400/30500/30600/30800/30801/30100/30103/30120/30140",
      longSize: `3"/4"/5"/6"/8"/8"/10"/10"/12"/14"`,
      longThickness: `2.9~3.4/3.4~4.0/3.8~4.4/4.5~5.2/5.3~6.1/5.3~6.1/6.6~7.6/6.6~7.6/7.8~9.0/8.5~9.8`,
      longLength: `3mtr/3mtr/3mtr/3mtr/3mtr/6mtr/3mtr/6mtr/3mtr/3mtr`,
      longColor: `Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
    },
    {
      id: 1015,
      category: "PRESSURE PIPE",
      imgSrc: upvc14,
      title: "Class C Filter Pipe",
      button: "Details",
      longCode: "30205/30315/30405/30505/30605/30805/30806/30105/30106/30125/30145",
      longSize: `2"/3"/4"/5"/6"/8"/8"/10"/10"/12"/14"`,
      longThickness: `2.5~3.0/3.5~4.1/4.5~5.2/5.5~6.4/6.6~7.6/7.8~9.0/7.8~9.0/9.7~11.2/9.7~11.2/11.5~13.3/12.6~14.5`,
      longLength: `3mtr/3mtr/3mtr/3mtr/3mtr/3mtr/6mtr/3mtr/6mtr/3mtr/3mtr`,
      longColor: `Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray/Gray`
    },
    {
      id: 1016,
      category: "PRESSURE PIPE",
      imgSrc: upvc14,
      title: "Class D Filter Pipe",
      button: "Details",
      longCode: "30210/30320/30410/30411/30508/30610/30810",
      longSize: `2"/3"/4"/4"/5"/6"/8"`,
      longThickness: `3.1~3.7/4.6~5.3/6.0~6.9/6.0~6.9/7.3~8.0/8.8~10.2/10.2~11.9`,
      longLength: `3mtr/3mtr/3mtr/6mtr/3mtr/3mtr/3mtr`,
      longColor: `Gray/Gray/Gray/Gray/Gray/Gray/Gray`
    },
    {
      id: 1017,
      category: "PRESSURE PIPE",
      imgSrc: upvc14,
      title: "Class E Filter Pipe",
      button: "Details",
      longCode: "30325/30415/30615/30815",
      longSize: `3"/4"/6"/8"`,
      longThickness: `5.7~6.6/7.3~8.4/10.8~12.5/12.6~14.5`,
      longLength: `3mtr/3mtr/3mtr/3mtr`,
      longColor: `Gray/Gray/Gray/Gray`
    },
    {
      id: 1018,
      category: "PRESSURE PIPE",
      imgSrc: upvc18,
      title: "Small dia rib & robo filter Pipe",
      button: "Details",
      longCode: "30150/30155/30160/30165/30170/30175/30180/37150/37155/37156/37160/37160",
      longSize: `1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"/1.5"`,
      longThickness: `1.3~1.5/1.3~1.5/1.3~1.5/1.9~2.1/2.4~2.6/2.7~3.0/2.5~3.0/1.2~1.4/1.2~1.4/1.2~1.5/1.2~1.4/1.2~1.4`,
      longLength: `6mtr/8mtr/10mtr/10mtr/10mtr/10mtr/6.5mtr/6mtr/8mtr/8mtr/9mtr/9mtr`,
      longColor: `Pink/Pink/Pink/Pest/Red/Gray/Gray/Off White/Off White/Pink/Off White/Pink`
    },

//                        upvc Fittings
//   color2 not initialized
    {
      id: 1101,
      category: "SWR PIPE",
      imgSrc: fittings1,
      title: "uPvc Fabricated Tee",
      button: "Details",
      code: "",
      size: `Customize`,
      color2: `white/gray`
    },
    {
      id: 1102,
      category: "SWR PIPE",
      imgSrc: fittings2,
      title: "uPvc Fabricated Bend",
      button: "Details",
      code: "",
      size: `Customize`,
      color2: `white/gray`
    },
    {
      id: 1103,
      category: "SWR PIPE",
      imgSrc: fittings3,
      title: "uPvc Fabricated End Cap",
      button: "Details",
      code: "",
      size: `Customize`,
      color2: `white/gray`
    },
    {
      id: 1104,
      category: "SWR PIPE",
      imgSrc: fittings4,
      title: "uPvc Riser Valve",
      button: "Details",
      code: "",
      size: `Customize`,
      color2: `white/gray`
    },
    {
      id: 1105,
      category: "SWR PIPE",
      imgSrc: fittings5,
      title: `110mm Plain Bend 90° FF`,
      button: "Details",
      code: "33490",
    },
    {
      id: 1106,
      category: "SWR PIPE",
      imgSrc: fittings6,
      title: `110mm Plain Bend 45° FF`,
      button: "Details",
      code: "33450",
    },
    {
      id: 1107,
      category: "SWR PIPE",
      imgSrc: fittings7,
      title: `110mm Plain Bend 45° MF`,
      button: "Details",
      code: "33451",
    },
    {
      id: 1108,
      category: "SWR PIPE",
      imgSrc: fittings8,
      title: `110mm Back Door Bend 90°`,
      button: "Details",
      code: "33491",
    },
    {
      id: 1109,
      category: "SWR PIPE",
      imgSrc: fittings9,
      title: `110mm Left Door Bend 90°`,
      button: "Details",
      code: "33492",
    },
    {
      id: 1110,
      category: "SWR PIPE",
      imgSrc: fittings10,
      title: `110mm Right Door Bend 90°`,
      button: "Details",
      code: "33493",
    },
    {
      id: 1111,
      category: "SWR PIPE",
      imgSrc: fittings11,
      title: `110mm Plain Tee`,
      button: "Details",
      code: "33400",
    },
    {
      id: 1112,
      category: "SWR PIPE",
      imgSrc: fittings12,
      title: `110mm Back Door Tee`,
      button: "Details",
      code: "33401",
    },
    {
      id: 1113,
      category: "SWR PIPE",
      imgSrc: fittings13,
      title: `110mm Left Door Tee`,
      button: "Details",
      code: "33402",
    },
    {
      id: 1114,
      category: "SWR PIPE",
      imgSrc: fittings14,
      title: `110mm Right Door Tee`,
      button: "Details",
      code: "33403",
    },
    {
      id: 1115,
      category: "SWR PIPE",
      imgSrc: fittings15,
      title: `110mm Cross Tee`,
      button: "Details",
      code: "33450",
    },
    {
      id: 1116,
      category: "SWR PIPE",
      imgSrc: fittings16,
      title: `110mm Cross Door Tee`,
      button: "Details",
      code: "33406",
    },
    {
      id: 1117,
      category: "SWR PIPE",
      imgSrc: fittings17,
      title: `110mm U Trap`,
      button: "Details",
      code: "33408",
    },
    {
      id: 1118,
      category: "SWR PIPE",
      imgSrc: fittings18,
      title: `110mm Long Trap`,
      button: "Details",
      code: "33409",
    },
    {
      id: 1119,
      category: "SWR PIPE",
      imgSrc: fittings19,
      title: `110mm Off set`,
      button: "Details",
      code: "33411",
    },
    {
      id: 1120,
      category: "SWR PIPE",
      imgSrc: fittings20,
      title: `110mm Pipe Clamp/Clip`,
      button: "Details",
      code: "33412",
    },
    {
      id: 1121,
      category: "SWR PIPE",
      imgSrc: fittings21,
      title: `110mm socket`,
      button: "Details",
      code: "33413",
    },
    {
      id: 1122,
      category: "SWR PIPE",
      imgSrc: fittings22,
      title: `110mmX55mm Reducing Bush`,
      button: "Details",
      code: "33414",
    },
    {
      id: 1123,
      category: "SWR PIPE",
      imgSrc: fittings23,
      title: `160mm Plain Tee With Door`,
      button: "Details",
      code: "33415",
    },
    {
      id: 1124,
      category: "SWR PIPE",
      imgSrc: fittings24,
      title: `160mm Plain Tee Without Door`,
      button: "Details",
      code: "33416",
    },
    {
      id: 1125,
      category: "SWR PIPE",
      imgSrc: fittings25,
      title: `160mmX110mm Plain Reduced Tee`,
      button: "Details",
      code: "33417",
    },
    {
      id: 1126,
      category: "SWR PIPE",
      imgSrc: fittings26,
      title: `160mm Plain Bend With Door`,
      button: "Details",
      code: "33418",
    },
    {
      id: 1127,
      category: "SWR PIPE",
      imgSrc: fittings27,
      title: `160mm Plain Bend Without Door`,
      button: "Details",
      code: "33419",
    },
    {
      id: 1128,
      category: "SWR PIPE",
      imgSrc: fittings28,
      title: `160mmX110mm Reducer`,
      button: "Details",
      code: "33420",
    },
    {
      id: 1129,
      category: "SWR PIPE",
      imgSrc: fittings29,
      title: `32mm Elbow 90°`,
      button: "Details",
      code: "33421",
    },
    {
      id: 1130,
      category: "SWR PIPE",
      imgSrc: fittings30,
      title: `40mm Elbow 90°`,
      button: "Details",
      code: "33422",
    },
    {
      id: 1131,
      category: "SWR PIPE",
      imgSrc: fittings31,
      title: `50mm Elbow 90°`,
      button: "Details",
      code: "33423",
    },  
    {    
      id: 1132,
      category: "SWR PIPE",
      imgSrc: fittings32,
      title: `1/2" Elbow 90°`,
      button: "Details",
      code: "33424",
    },
    {
      id: 1133,
      category: "SWR PIPE",
      imgSrc: fittings33,
      title: `3/4" Elbow 90°`,
      button: "Details",
      code: "33425",
    },
    {
      id: 1134,
      category: "SWR PIPE",
      imgSrc: fittings34,
      title: `1" Elbow 90°`,
      button: "Details",
      code: "33426",
    },
    {
      id: 1135,
      category: "SWR PIPE",
      imgSrc: fittings35,
      title: `1-1/2" Elbow 90°`,
      button: "Details",
      code: "33427",
    },
    {
      id: 1136,
      category: "SWR PIPE",
      imgSrc: fittings36,
      title: `2" Elbow 90°`,
      button: "Details",
      code: "33428",
    },

    // last 4 ta new

    {
      id: 1137,
      category: "SWR PIPE",
      imgSrc: fittings37,
      title: `110mm U Trap Mould`,
      button: "Details",
      code: "33429",
    },
    {
      id: 1138,
      category: "SWR PIPE",
      imgSrc: fittings38,
      title: `250mm Tee`,
      button: "Details",
      code: "33430",
    },
    {
      id: 1139,
      category: "SWR PIPE",
      imgSrc: fittings39,
      title: `250mm Bend`,
      button: "Details",
      code: "33431",
    },
    {
      id: 1140,
      category: "SWR PIPE",
      imgSrc: fittings40,
      title: `250mm End cap`,
      button: "Details",
      code: "33432",
    },
     
  //                        UPVC Tubewell -> 1080
     
  {
    id: 1080,
    category: "TUBEWELL",
    imgSrc: tubewell1,
    title: "BRDB Tubewell",
    code: "83125",
    button: "Details"
  },
  {
    id: 1081,
    category: "TUBEWELL",
    imgSrc: tubewell2,
    title: `Unicef/DPHE/Hyswa Tubewell`,
    code: "83101",
    // longSize: `/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1082,
    category: "TUBEWELL",
    imgSrc: tubewell3,
    title: "Samrat Tubewell",
    code: "83102",
    // longSize: `Unicef | DPHE | Hyswa Tubwell/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1083,
    category: "TUBEWELL",
    imgSrc: tubewell4,
    title: "Standard Tubewell",
    code: "83107",
    // longSize: `Unicef | DPHE | Hyswa Tubwell/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1084,
    category: "TUBEWELL",
    imgSrc: tubewell5,
    title: "Mini Tubewell",
    code: "83141",
    // longSize: `Unicef | DPHE | Hyswa Tubwell/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1085,
    category: "TUBEWELL",
    imgSrc: tubewell6,
    title: "Heavy Tubewell",
    code: "83115",
    // longSize: `Unicef | DPHE | Hyswa Tubwell/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1086,
    category: "TUBEWELL",
    imgSrc: tubewell7,
    title: "Super Tubewell",
    code: "83109",
    // longSize: `Unicef | DPHE | Hyswa Tubwell/ Samrat TubeWell/Curve Popular Heavy TubeWell/Curve Popular Super TubeWell/Standard TubeWell/Super Straight TubeWell/Curve Popular Super TubeWell/ Mini Popular TubeWell/Mini Heavy TubeWell/ Mini Light TubeWell/Heavy TubeWell/Light Straight TubeWell/ BRDB | Talukder TubeWell (25.5-26kg)/BRDB | Talukder TubeWell (25.5-26kg) (Red)  `,
    button: "Details"
  },
  {
    id: 1087,
    category: "TUBEWELL",
    imgSrc: tubewell8,
    title: "Super Medium Tubewell",
    code: "83107",
    button: "Details"
  },
  
  //                       INDUSTRIAL FURNITURE (Rack)


  {
    id: 701,
    title: "Industrial Storage Solution",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack1,
    button: "Details"
  },
  {
    id: 702,
    title: "Industrial Storage Solution",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack2,
    button: "Details"
  },
  {
    id: 703,
    title: "Industrial Storage Solution",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack3,
    button: "Details"
  },
  {
    id: 704,
    title: "Industrial Storage Solution",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack4,
    button: "Details"
  },
  {
    id: 705,
    title: "SLOTTED ANGLE/Light duty RACK",
    // code: "TFSAR-068/TFSAR-069",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack5,
    button: "Details"
  },
  {
    id: 706,
    title: "Super shop display RACK(L Type)",
    category: "INDUSTRIAL FURNITURE",
    size: `L3'-0" X W1'-6" X 5'-6"`,
    size2: `L4'-0" X W1'-6" X 7'-0"`,
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack6,
    button: "Details"
  },
  {
    id: 707,
    title: "Super shop display RACK(T Type)",
    category: "INDUSTRIAL FURNITURE",
    size2: `L3'-0" X W3'-2" X 5'-6"`,
    size2: `L4'-0" X W3'-2" X 7'-0"`,
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack7,
    button: "Details"
  },
  {
    id: 708,
    title: "Super shop display RACK(L Type)",
    category: "INDUSTRIAL FURNITURE",
    size: `L3'-0" X W1'-6" X 5'-6"`,
    size2: `L4'-0" X W1'-6" X 7'-0"`,
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack8,
    button: "Details"
  },
  {
    id: 709,
    title: "Super shop display RACK(T Type)",
    category: "INDUSTRIAL FURNITURE",
    size: `L3'-0" X W3'-2" X 5'-6"`,
    size2: `L4'-0" X W3'-2" X 7'-0"`,
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack9,
    button: "Details"
  },
  {
    id: 710, 
    title: "Heavy Duty Industrial RACK",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack10,
    button: "Details"
  },
  {
    id: 711, 
    title: "Heavy Duty Industrial RACK",
    category: "INDUSTRIAL FURNITURE",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: rack11,
    button: "Details"
  },
  // {
  //   id: 712, 
  //   title: "Industrial’s Slotted Angle Rack",
  //   category: "INDUSTRIAL FURNITURE",
  //   code: "TFSAR-082",
  //   size: "4’x1.5’x8′ Feet",
  //   material: "Mild Steel",
  //   Warranty:"One year free service warranty for any manufacturing fault",
  //   imgSrc: rack12,
  //   button: "Details"
  // },





    // office purniture
  {
    id: 1,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-001",
    size: "L2400 X W1900 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    pollish: "lacquer polish",
    imgSrc: tbl1,
    button: "Details",
    // imgSrc:[ tbl1, tbl2, tbl3, tbl4 ]
  },
  {
    id: 2,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-002",
    size: "L2400 X W1950 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    pollish: "lacquer polish",
    imgSrc: tbl2,
    button: "Details",
  },
  {
    id: 3,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-003",
    size: "L2100 X W1800 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl3,
    button: "Details"
  },
  {
    id: 4,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-004",
    size: "L2100 X W1800 X H750mm",
    material: "OAK & Beech wood.",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl4,
    button: "Details"
  },
  {
    id: 5,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-005",
    size: "L2100 X W1800 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl5,
    button: "Details"
  },
  {
    id: 6,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-006",
    size: "L1950 X W1850 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl6,
    button: "Details"
  },
  {
    id: 7,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-007",
    size: "L2100 X W1850 X H750mm",
    material: "OAK & Beech wood.",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl7,
    button: "Details"
  },
  {
    id: 8,
    title: "MD TABLE",
    category: "office furniture",
    code: "TFMTO-008",
    size: "L1800 X W1650 X H750mm",
    material: "OAK & Beech wood.",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl8,
    button: "Details"
  },
  {
    id: 9,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-009",
    size: "L1800 X W1800 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl9,
    button: "Details"
  },
  {
    id: 10,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-010",
    size: "L1900 X W1900 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl10,
    button: "Details"
  },
  {
    id: 11,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-011",
    size: "L2400 X W1900 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl11,
    button: "Details"
  },
  {
    id: 12,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-012",
    size: "L2100 X W1800 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl12,
    button: "Details"
  },
  {
    id: 13,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-013",
    size: "L2100 X W1800 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl13,
    button: "Details"
  },
  {
    id: 14,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-014",
    size: "L2400 X W1900 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl14,
    button: "Details"
  },
  {
    id: 15,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-015",
    size: "L1800 X W1650 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl15,
    button: "Details"
  },
  {
    id: 16,
    title: "DIRECTOR TABLE",
    category: "office furniture",
    code: "TFDTO-016",
    size: "L1800 X W1650 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl16,
    button: "Details"
  },
  {
    id: 17,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-017",
    size: "L1650 X W1650 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl17,
    button: "Details"
  },
  {
    id: 18,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-018",
    size: "L1800 X W1750 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl18,
    button: "Details"
  },
  {
    id: 19,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-019",
    size: "L1800 X W1750 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl19,
    button: "Details"
  },
  {
    id: 20,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-020",
    size: "L1600 X W750 X H750m",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl20,
    button: "Details"
  },
  {
    id: 21,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-021",
    size: "L1650 X W700 X H750m",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl21,
    button: "Details"
  },
  {
    id: 22,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-022",
    size: "L1650 X W700 X H750m",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl22,
    button: "Details"
  },
  {
    id: 23,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-023",
    size: "L1800 X W1500 X H750m",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl23,
    button: "Details"
  },
  {
    id: 24,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-024",
    size: "L1500 X W650 X H750m",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl24,
    button: "Details"
  },
  {
    id: 25,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-025",
    size: "L1650 X W750 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl25,
    button: "Details"
  },
  {
    id: 26,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-026",
    size: "L1650 X W750 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl26,
    button: "Details"
  },
  {
    id: 27,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-027",
    size: "L1500 X W700 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl27,
    button: "Details"
  },
  {
    id: 28,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-028",
    size: "L1400 X W700 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl28,
    button: "Details"
  },
  {
    id: 29,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-029",
    size: "L1200 X W600 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl29,
    button: "Details"
  },
  {
    id: 30,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-030",
    size: "L1200 X W600 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl30,
    button: "Details"
  },
  {
    id: 31,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-031",
    size: "L1350 X W600 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl31,
    button: "Details"
  },
  {
    id: 32,
    title: "EXECUTIVE TABLE",
    category: "office furniture",
    code: "TFETO-032",
    size: "L1200 X W600 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl32,
    button: "Details"
  },
  {
    id: 33,
    title: "MOVABLE DRAWER UNIT",
    category: "office furniture",
    code: "TFDUO-033",
    size: "L400 X W460 X H500mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl33,
    button: "Details"
  },
  {
    id: 34,
    title: "MOVABLE DRAWER UNIT",
    category: "office furniture",
    code: "TFDUO-034",
    size: "L400 X W460 X H500mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl34,
    button: "Details"
  },
  {
    id: 35,
    title: "MOVABLE DRAWER UNIT",
    category: "office furniture",
    code: "TFDUO-035",
    size: "L450 X W480 X H650mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl35,
    button: "Details"
  },
  {
    id: 36,
    title: "MOVABLE DRAWER UNIT",
    category: "office furniture",
    code: "TFDUO-036",
    size: "L400 X W480 X H625mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl36,
    button: "Details"
  },
  {
    id: 37,
    title: "MOVABLE DRAWER UNIT",
    category: "office furniture",
    code: "TFDUO-037",
    size: "L420 X W480 X H650mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl37,
    button: "Details"
  },
  {
    id: 38,
    title: "HANGING DRAWER UNIT",
    category: "office furniture",
    code: "TFHDO-038",
    size: "L450 X W460 X H350mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl38,
    button: "Details"
  },
  {
    id: 39,
    title: "HANGING DRAWER UNIT",
    category: "office furniture",
    code: "TFHDO-039",
    size: "L400 X W460 X H380mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl39,
    button: "Details"
  },
  {
    id: 40,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-040",
    size: "L1200 X W400 X H650mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl40,
    button: "Details"
  },

  {
    id: 41,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-041",
    size: "L1000 X W400 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl41,
    button: "Details"
  },

  {
    id: 42,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-042",
    size: "L1000 X W400 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl42,
    button: "Details"
  },

  {
    id: 43,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-043",
    size: "L1050 X W400 X H680mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl43,
    button: "Details"
  },

  {
    id: 44,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-044",
    size: "L1000 X W400 X H680mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl44,
    button: "Details"
  },

  {
    id: 45,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-045",
    size: "L900 X W400 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl45,
    button: "Details"
  },

  {
    id: 46,
    title: "SIDE RACK",
    category: "office furniture",
    code: "TFSRO-046",
    size: "L900 X W400 X H650mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl46,
    button: "Details"
  },
  {
    id: 47,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-047",
    size: "L1500 X W600 X H750mm",
    material: "Mfc board & Metal Strcture",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl47,
    button: "Details"
  },
  {
    id: 48,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-048",
    size: "L1200 X W500 X H750mm",
    material: "Mfc board & Metal Strcture",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl48,
    button: "Details"
  },
  {
    id: 49,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-049",
    size: "L900 X W480 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl49,
    button: "Details"
  },
  {
    id: 50,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-050",
    size: "L1050 X W480 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl50,
    button: "Details"
  },
  {
    id: 51,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-051",
    size: "L1050 X W480 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl51,
    button: "Details"
  },
  {
    id: 52,
    title: "COMPUTER TABLE",
    category: "office furniture",
    code: "TFCTO-052",
    size: "L1000 X W480 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl52,
    button: "Details"
  },

  //  BAR STOOL
  {
    id: 53,
    title: "BAR STOOL",
    category: "office furniture",
    code: "TFBST-053",
    material: "Metal strcture and powder coating painting",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl53,
    button: "Details"
  },
  {
    id: 54,
    title: "BAR STOOL",
    category: "office furniture",
    code: "TFBST-054",
    material: "Beech wood and veneered engineered wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl54,
    button: "Details"
  },
  {
    id: 55,
    title: "BAR STOOL",
    category: "office furniture",
    code: "TFBST-055",
    material: "Beech wood and veneered engineered wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl55,
    button: "Details"
  },
  // BAR CABINET
  {
    id: 56,
    title: "BAR CABINET",
    category: "office furniture",
    code: "TFBCH-056",
    size: "L1600 X W400 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl56,
    button: "Details"
  },

  {
    id: 57,
    title: "BAR CABINET",
    category: "office furniture",
    code: "TFBCH-057",
    size: "L1200 X W350 X H900mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl57,
    button: "Details"
  },

  // MULTIPURPOSE SHELF

  {
    id: 58,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-058",
    size: "L1200 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl58,
    button: "Details"
  },

  {
    id: 59,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-059",
    size: "L800 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl59,
    button: "Details"
  },
  {
    id: 60,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-060",
    size: "L800 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl60,
    button: "Details"
  },
  {
    id: 61,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-061",
    size: "L600 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl61,
    button: "Details"
  },
  {
    id: 62,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-062",
    size: "L800 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl62,
    button: "Details"
  },
  {
    id: 63,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-063",
    size: "L900 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl63,
    button: "Details"
  },
  {
    id: 64,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-064",
    size: "L1200 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl64,
    button: "Details"
  },
  {
    id: 65,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-065",
    size: "L1200 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl65,
    button: "Details"
  },
  {
    id: 66,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-066",
    size: "L600 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl66,
    button: "Details"
  },

  {
    id: 67,
    title: "MULTIPURPOSE SHELF",
    category: "office furniture",
    code: "TFMPO-067",
    size: "L2400 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl67,
    button: "Details"
  },
  // // WALL MOUNTED CABINET
  {
    id: 71,
    title: "WALL MOUNTED CABINET",
    category: "office furniture",
    code: "TFWMC-071",
    size: "L1600 X W350 X H600mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl71,
    button: "Details"
  },

  {
    id: 72,
    title: "WALL MOUNTED CABINET",
    category: "office furniture",
    code: "TFWMC-072",
    size: "L900 X W350 X H700mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl72,
    button: "Details"
  },

  // //BACK CABINET

  {
    id: 73,
    title: "BACK CABINET",
    category: "office furniture",
    code: "TFBCO-073",
    size: "L1600 X W450 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl73,
    button: "Details"
  },

  {
    id: 74,
    title: "BACK CABINET",
    category: "office furniture",
    code: "TFBCO-074",
    size: "L1650 X W450 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl74,
    button: "Details"
  },
  {
    id: 75,
    title: "BACK CABINET",
    category: "office furniture",
    code: "TFBCO-075",
    size: "L1200 X W400 X H750mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl75,
    button: "Details"
  },

  //        low height Cabinet
  {
    id: 91,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 001 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet1,
    button: "Details"
  },
  {
    id: 92,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 002 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet2,
    button: "Details"
  },
  {
    id: 93,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 003 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet3,
    button: "Details"
  },
  {
    id: 94,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 004 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet4,
    button: "Details"
  },
  {
    id: 95,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 005 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet5,
    button: "Details"
  },
  {
    id: 96,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 006 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet6,
    button: "Details"
  },
  {
    id: 97,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 007 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet7,
    button: "Details"
  },
  {
    id: 98,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 008 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet8,
    button: "Details"
  },
  {
    id: 99,
    title: "Side Table",
    category: "office furniture",
    code: "THCB 009 WD",
    size: "L1200 X W400 X H750mm",
    imgSrc: lowCabinet9,
    button: "Details"
  },
  
  // FULL HEIGHT FILE CABINET

  {
    id: 76,
    title: "FULL HEIGHT FILE CABINET",
    category: "office furniture",
    code: "TFFHB-076",
    size: "L2400 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl76,
    button: "Details"
  },
  {
    id: 77,
    title: "FULL HEIGHT FILE CABINET",
    category: "office furniture",
    code: "TFFHB-077",
    size: "L2400 X W400 X H1800mm",
    material: "Mfc / lb board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl77,
    button: "Details"
  },

  // fileCabinet1

  {
    id: 81,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 001 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet1,
    button: "Details"
  },
  {
    id: 82,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 002 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet2,
    button: "Details"
  },
  {
    id: 83,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 003 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet3,
    button: "Details"
  },
  // {
  //   id: 84,
  //   title: "FILE CABINET",
  //   category: "office furniture",
  //   code: "THCB 004 WD",
  //   size: "L2400 X W400 X H1800mm",
  //   imgSrc: fileCabinet4,
  //   button: "Details"
  // },
  {
    id: 85,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 005 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet5,
    button: "Details"
  },
  {
    id: 86,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 006 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet6,
    button: "Details"
  },
  {
    id: 87,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 007 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet7,
    button: "Details"
  },
  {
    id: 88,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 008 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet8,
    button: "Details"
  },
  {
    id: 89,
    title: "FILE CABINET",
    category: "office furniture",
    code: "THCB 009 WD",
    size: "L2400 X W400 X H1800mm",
    imgSrc: fileCabinet9,
    button: "Details"
  },

  //         GARDEN FURNITURE
  {
    id: 78,
    title: "GARDEN FURNITURE",
    category: "office furniture",
    code: "TFGWS-078",
    size: "L1500 X W560 X H900mm",
    material: "Oak & Beech Wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl78,
    button: "Details"
  },

  {
    id: 79,
    title: "GARDEN FURNITURE",
    category: "office furniture",
    code: "TFGWS-079",
    size: "L450 X W500 X H950mm",
    material: "Oak & Beech Wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl79,
    button: "Details"
  },

  // //SWIMMING POOL FURNITURE
  {
    id: 80,
    title: "SWIMMING POOL FURNITURE",
    category: "office furniture",
    code: "TFSPF-080",
    size: "L1980 X W660 X H790mm",
    material: "Oak & Beech Wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: tbl80,
    button: "Details"
  },

  
  ///                             OFFICE FURNITURE CHAIR -> (501 - )

  ///                         conference table(401-408)

  {
    id: 401,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-001",
    size: "L3600 X W1200 X H750mm",
    material: "Mfc / Lb Board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table1,
    button: "Details"
  },
  {
    id: 402,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-002",
    size: "L3600 X W1200 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table2,
    button: "Details"
  },
  {
    id: 403,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-003",
    size: "L6000 X W1500 X H750mm",
    material: "Mfc / Lb Board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table3,
    button: "Details"
  },
  {
    id: 404,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-004",
    size: "L2400 X W1200 X H750mm",
    material: "Mfc / Lb Board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table4,
    button: "Details"
  },
  {
    id: 405,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-005",
    size: "L2400 X W1200 X H750mm",
    material: "Mfc / Lb Board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table5,
    button: "Details"
  },
  {
    id: 406,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-006",
    size: "L3000 X W1200 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table6,
    button: "Details"
  },
  {
    id: 407,
    title: "conference table",
    category: "office furniture",
    code: "TFCON-007",
    size: "L2400 X W1200 X H750mm",
    material: "Mahogany & Oak Veneered engineering wood",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table7,
    button: "Details"
  },
  {
    id: 408,
    title: "meeting table",
    category: "office furniture",
    code: "TFCON-008",
    size: "L1200 X W1200 X H750mm",
    material: "Mfc / Lb Board",
    Warranty:"One year free service warranty for any manufacturing fault",
    imgSrc: con_table8,
    button: "Details"
  },

  // ///                         SWIVEL CHAIR(501 - 5 )

  {
    id: 501,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-001",
    imgSrc: chair1,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 502,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-002",
    imgSrc: chair2,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 503,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-003",
    imgSrc: chair3,
    button: "Details",
    material: "cold rolled mild steel. All surface of mild steel zinc phosphate coated oven backed Powder paint finish which will ensure durable anti peel off. Fully covered seat and back by finest quality imported fabric which ensures longevity & comfort. Using durable PVC armrest. High quality Revolving chair with back tilt facility .Manual height adjustable. Metal chrome leg with 5nos castors which ensure longevity"
  },
  {
    id: 504,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-004",
    imgSrc: chair4,
    button: "Details",
    material: "cold rolled mild steel. All surface of mild steel zinc phosphate coated oven backed Powder paint finish which will ensure durable anti peel off. Fully covered seat and back by finest quality imported fabric which ensures longevity & comfort. Using durable PVC armrest. High quality Revolving chair with back tilt facility .Manual height adjustable. Metal chrome leg with 5nos castors which ensure longevity"
  },
  {
    id: 505,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-005",
    imgSrc: chair5,
    button: "Details",
    material: "solid Mahagony seasoned wood. Fully Solid wooden armrest. Chrome plated polymer cast leg with 5nos. castors offers stability and visual interest. Hydraulically height adjustable mechanism. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 506,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-006",
    imgSrc: chair6,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility."
  },
  {
    id: 507,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-007",
    imgSrc: chair7,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 508,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-008",
    imgSrc: chair8,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 509,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-009",
    imgSrc: chair9,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 510,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-010",
    imgSrc: chair10,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 511,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-011",
    imgSrc: chair11,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 512,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-012",
    imgSrc: chair12,
    button: "Details",
    material: " high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 513,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFMCO-013",
    imgSrc: chair13,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality Artificial Leather upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 514,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-014",
    imgSrc: chair14,
    button: "Details",
    material: "high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor. High quality fabric upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 515,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-015",
    imgSrc: chair15,
    button: "Details",
    material: "high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor. High quality rexine upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 516,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-016",
    imgSrc: chair16,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality rexine upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 517,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-017",
    imgSrc: chair17,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality rexine upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 518,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-018",
    imgSrc: chair18,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality fabric upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 519,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-019",
    imgSrc: chair19,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality rexine upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 520,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-020",
    imgSrc: chair20,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality fabric upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 521,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-021",
    imgSrc: chair21,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality fabric upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 522,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-022",
    imgSrc: chair22,
    button: "Details",
    material: "Chrome plated polymer cast leg with 5 nos. High quality fabric upholstery with soft and durable cushioning. Smooth gliding casters provide increased comfort and mobility"
  },
  {
    id: 523,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-023",
    imgSrc: chair23,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 524,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFECO-024",
    imgSrc: chair24,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 525,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-025",
    imgSrc: chair25,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 526,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-026",
    imgSrc: chair26,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 527,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-027",
    imgSrc: chair27,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  }, 
  {
    id: 528,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-028",
    imgSrc: chair28,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 529,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-029",
    imgSrc: chair29,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 530,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TF0CO-030",
    imgSrc: chair30,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 531,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFOCO-031",
    imgSrc: chair31,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 532,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFOCO-032",
    imgSrc: chair32,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported nylon fabrics on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table.PVC legs with 5nos castors which ensure no rust"
  },
  {
    id: 533,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFOCO-033",
    imgSrc: chair33,
    button: "Details",
    material: "high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor"
  },
  {
    id: 534,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-034",
    imgSrc: chair34,
    button: "Details",
    material: "high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor"
  },
  {
    id: 535,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-035",
    imgSrc: chair35,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. 50mm thick foam cushioning covered with best quality imported nylon rexine on 19 mm ply wood & back which increase strength. Hydraulically height adjustable mechanism facility helps to regulate with table. PVC legs with 5nos castors which ensure no rust. Best quality dual-wheel provides easy mobility, Star head binding screw, best quality handle. "
  },
  {
    id: 536,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-036",
    imgSrc: chair36,
    button: "Details",

  },
  {
    id: 537,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-037",
    imgSrc: chair37,
    button: "Details"
  },
  {
    id: 538,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-038",
    imgSrc: chair38,
    button: "Details",
    material: "superior quality chrome plated mild steel and Nylon base. High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options. Metal Inert Gas (MIG) welding for superior quality and durability "
  },
  {
    id: 539,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-039",
    imgSrc: chair39,
    button: "Details",
    material: "superior quality chrome plated mild steel and Nylon base. High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options. Metal Inert Gas (MIG) welding for superior quality and durability "
  },
  {
    id: 540,
    title: "SWIVEL CHAIR",
    category: "office furniture",
    code: "TFCCO-040",
    imgSrc: chair40,
    button: "Details",
    material: "superior quality chrome plated mild steel and Nylon base. High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options. Metal Inert Gas (MIG) welding for superior quality and durability "
  },

  {
    id: 541,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-041",
    imgSrc: chair41,
    button: "Details",
    material: "This chair is made from high quality imported accessories and supported by metal body. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor"
  },
  {
    id: 542,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-042",
    imgSrc: chair42,
    button: "Details",
    material: "cold rolled mild steel. All surface of mild steel zinc phosphate coated oven backed Powder paint finish which will ensure durable anti peel off. Fully covered seat and back by finest quality imported fabric which ensures longevity & comfort. Using durable PVC armrest. High quality Revolving chair with back tilt facility .Manual height adjustable. Metal chrome leg with 5nos castors which ensure longevity"
  },
  {
    id: 543,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-043",
    imgSrc: chair43,
    button: "Details",
    material: "cold rolled mild steel. All surface of mild steel zinc phosphate coated oven backed Powder paint finish which will ensure durable anti peel off. Fully covered seat and back by finest quality imported fabric which ensures longevity & comfort. Using durable PVC armrest. High quality Revolving chair with back tilt facility. Manual height adjustable"
  },
  {
    id: 544,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-044",
    imgSrc: chair44,
    button: "Details",
    material: "cold rolled mild steel. All surface of mild steel zinc phosphate coated oven backed Powder paint finish which will ensure durable anti peel off. Fully covered seat and back by finest quality imported fabric which ensures longevity & comfort. Using durable PVC armrest. High quality Revolving chair with back tilt facility. Manual height adjustable"
  },
  {
    id: 545,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-045",
    imgSrc: chair45,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table"
  },
  {
    id: 546,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-046",
    imgSrc: chair46,
    button: "Details",
    material: "cold mild steel. All surface of mild steel zinc phosphate coated oven backed powder paint finish which will ensure durable anti peel off. foam cushioning covered with best quality imported rexine on wood & back which increase strength. High quality revolving chair back tilt facilities and strong PVC arms. Hydraulically height adjustable mechanism facility helps to regulate with table"
  },
  {
    id: 547,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-047",
    imgSrc: chair47,
    button: "Details",
    material: "Stainless steel welded framework that ensures high load. Zinc-phosphate coated electro-static epoxy powder oven baked paint finish that confirms anti-rust, shiny color and longevity"
  },
  {
    id: 548,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-048",
    imgSrc: chair48,
    button: "Details",
    material: "welded stainless  steel and is protected with  an anti-rust coating for longevity of its life. The seat and back support are upholstered with a tough, superior quality rexine material to prevent damage. The seat and the back support of this chair provide good lumbar support for your comfort. The legs of this chair are attached with high density rubber to prevent scratches on the floor"
  },
  {
    id: 549,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-049",
    imgSrc: chair49,
    button: "Details",
    material: "welded stainless  steel and is protected with  an anti-rust coating for longevity of its life. The seat and back support are upholstered with a tough, superior quality rexine material to prevent damage. The seat and the back support of this chair provide good lumbar support for your comfort. The legs of this chair are attached with high density rubber to prevent scratches on the floor"

  },
  {
    id: 550,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-050",
    imgSrc: chair50,
    button: "Details",
    material: "Powder coated mild steel High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 551,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-051",
    imgSrc: chair51,
    button: "Details",
    material: "Powder coated mild steel High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 552,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-052",
    imgSrc: chair52,
    button: "Details",
    material: "Powder coated mild steel High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 553,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-053",
    imgSrc: chair53,
    button: "Details",
    material: "Powder coated mild steel High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 554,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-054",
    imgSrc: chair54,
    button: "Details",
    material: "solid wood and Powder coated mild steel. High quality environment friendly Italian Ultra Violet (UV) and Polyurethane (PU) Lacquer in antique finish "
  },
  {
    id: 555,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-055",
    imgSrc: chair55,
    button: "Details",
    material: "imported shell and High quality environment friendly Italian Ultra Violet (UV) and Polyurethane (PU) Lacquer in natural finish Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 556,
    title: "FIXED CHAIR",
    category: "office furniture",
    code: "TFVCO-056",
    imgSrc: chair56,
    button: "Details",
    material: ""
  },
  {
    id: 557,
    title: "AUDITORIUM CHAIR",
    category: "office furniture",
    code: "TFACO-057",
    imgSrc: chair57,
    button: "Details"
  },
  {
    id: 558,
    title: "AUDITORIUM CHAIR",
    category: "office furniture",
    code: "TFACO-058",
    imgSrc: chair58,
    button: "Details"
  },
  {
    id: 559,
    title: "GROUP CHAIR",
    category: "office furniture",
    code: "TFGCO-059",
    imgSrc: chair59,
    button: "Details",
    material: "solid wood and Powder coated mild steel waiting chairs will stand the test of time with its strength and longevity. The structure is made of mild steel which is absolutely rust free. The chrome plate color will provide great endurance over time. Perfect for public waiting places this chair comes in a set of three."
  },
  {
    id: 560,
    title: "GROUP CHAIR",
    category: "office furniture",
    code: "TFGCO-060",
    imgSrc: chair60,
    button: "Details",
    material: "cold rolled mild steel waiting chairs will stand the test of time with its strength and longevity. The structure is made of mild steel which is absolutely rust free. The chrome plate color will provide great endurance over time. Perfect for public waiting places this chair comes in a set of three"
  },
  {
    id: 561,
    title: "CLASSROOM CHAIR",
    category: "School Furniture",
    code: "TFCRC-061",
    imgSrc: chair61,
    button: "Details",
    material: "mild steel for that sturdy feel and balance as well as longevity in life. The wooden seat on top provides excellent lumbar support. The metal frame has an anti-rust coating to prevent rusting and damage. It is equipped with high quality rubber stoppers to protect floors from scratches"
  },
  {
    id: 562,
    title: "CLASSROOM CHAIR",
    category: "School Furniture",
    code: "TFCRC-062",
    imgSrc: chair62,
    button: "Details",
    material: "solid wood and Powder coated mild steel. High quality environment friendly Italian Ultra Violet (UV) and Polyurethane (PU) Lacquer in antique finish. The legs of the chair are fitted with heavy duty rubber also helps in preventing scratches on the floor"
  },
  {
    id: 563,
    title: "CLASSROOM CHAIR",
    category: "School Furniture",
    code: "TFCRC-063",
    imgSrc: chair63,
    button: "Details",
    material: "superior quality chrome plated mild steel.High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options"
  },
  {
    id: 564,
    title: "CLASSROOM CHAIR",
    category: "School Furniture",
    code: "TFCRC-064",
    imgSrc: chair64,
    button: "Details",
    material: "superior quality chrome plated mild steel.High quality fabric upholstery with soft and durable cushioning. Fabric can be selected from available options"
  },
  {
    id: 565,
    title: "CLASSROOM CHAIR",
    category: "School Furniture",
    code: "TFCRC-065",
    imgSrc: chair65,
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Metal Inert Gas (MIG) welding for superior quality and durability "
  },
  {
    id: 566,
    title: "CLASSROOM TABLE WITH BENCH",
    category: "School Furniture",
    code: "TFHLB-066",
    imgSrc: chair66,
    size: "L1800 X W400 X H730MM(HIGH BENCH)",
    size2: "L1800 X W300 X H400MM(LOW BENCH)",
    button: "Details",
    material: ""
  },
  {
    id: 567,
    title: "CLASSROOM TABLE WITH BENCH",
    category: "School Furniture",
    code: "TFHLB-067",
    imgSrc: chair67,
    size: "L1500 X W450 X H750MM(HIGH BENCH)",
    size2: "L1500 X W300 X H400MM(LOW BENCH)",
    button: "Details",
    material: ""
  },
  {
    id: 568,
    title: "TEACHERS TABLE",
    category: "School Furniture",
    code: "TFTTS-068",
    imgSrc: chair68,
    size: "L850 X W540 X H780MM",
    button: "Details",
    material: "mild welded steel it is durable and strong and ensures longevity in use. To add to the ergonomic design of the product it has a golden teak finish to give it a rich wooden look"
  },
  {
    id: 569,
    title: "TEACHERS TABLE",
    category: "School Furniture",
    code: "TFTTS-069",
    imgSrc: chair69,
    size: "L900 X W500 X H750MM",
    button: "Details",
    material: "mild welded steel it is durable and strong and ensures longevity in use. To add to the ergonomic design of the product it has a golden teak finish to give it a rich wooden look"
  },
  {
    id: 570,
    title: "PODIUM",
    category: "School Furniture",
    code: "TFPOD-070",
    imgSrc: chair70,
    size: "L750 X W550 X H1250MM",
    button: "Details",
    material: "Mfc / lb board. High quality environment friendly Italian Ultra Violet (UV) and Polyurethane (PU)Lacquer in antique (outside) and natural (inside) finish Please refer to images for dimension details"
  },
  {
    id: 571,
    title: "PODIUM",
    category: "School Furniture",
    code: "TFPOD-071",
    imgSrc: chair71,
    size: "L850 X W600 X H1250MM",
    button: "Details",
    material: "Beech wood and veneered engineered wood. High quality environment friendly Italian Ultra Violet (UV) and Polyurethane (PU)Lacquer in antique (outside) and natural (inside) finish Please refer to images for dimension details"
  },

  ////      FILE CABINET
  {
    id: 572,
    title: "FILE CABINET",
    category: "School Furniture",
    code: "TFSFC-072",
    imgSrc: chair72,
    size: "L482 X W550 X H720MM",
    button: "Details",
    material: "mild steel welded channel framework that ensure high load bearing - Zinc-phosphate coated electro-static epoxy powder oven baked paint finish that confirms anti-rust, shiny color and longevity"
  },
  {
    id: 573,
    title: "FILE CABINET",
    category: "School Furniture",
    code: "TFSFC-073",
    imgSrc: chair073,
    size: "L482 X W550 X H1040MM",
    button: "Details",
    material: "mild steel welded channel framework that ensure high load bearing - Zinc-phosphate coated electro-static epoxy powder oven baked paint finish that confirms anti-rust, shiny color and longevity"
  },
  // 573 & 574 CODE ARE SAME COZ I FOLLOW THE BUSHIER & SIR SUGGEST ME
  {
    id: 574,
    title: "FILE CABINET",
    category: "School Furniture",
    code : "TFSFC-073",
    imgSrc: chair73,
    size: "L482 X W550 X H1370MM",
    button: "Details",
    material: "mild steel welded channel framework that ensure high load bearing - Zinc-phosphate coated electro-static epoxy powder oven baked paint finish that confirms anti-rust, shiny color and longevity"
  },

  ///         COMBINED CABINET

  {
    id: 575,
    title: "COMBINED CABINET",
    category: "office furniture",
    code: "TFSCC-074",
    imgSrc: chair74,
    size: "L900 X W550 X H1200MM",
    button: "Details",
    material: "the World’s best Quality Mild Steel Sheet. All surface of Mild Steel are mig welded, chemically de-rusted and zinc phosphate coated with oven backed heated powder paint finish that confirms anti rust, shiny color and longevity. Imported High Quality Rust Free Hardware Fittings. PVC stopper used in bottom to prevents moisture content of the floor"
  },

  /// OFFICE ALMIRAH
  {
    id: 576,
    title: "STEEL ALMIRAH",
    category: "School Furniture",
    code: "TFSOA-075",
    imgSrc: chair75,
    size: "L900 X W500 X H1850MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 577,
    title: "STEEL ALMIRAH",
    category: "School Furniture",
    code: "TFSOA-076",
    imgSrc: chair76,
    size: "L910 X W480 X H1930MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 578,
    title: "STEEL ALMIRAH",
    category: "School Furniture",
    code: "TFSOA-077",
    imgSrc: chair77,
    size: "L910 X W480 X H1930MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 579,
    title: "OFFICE ALMIRAH",
    category: "office furniture",
    code: "TFSOA-078",
    imgSrc: chair78,
    size: "L900 X W450 X H1800MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 580,
    title: "OFFICE ALMIRAH",
    category: "office furniture",
    code: "TFSOA-079",
    imgSrc: chair79,
    size: "L900 X W450 X H1800MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 581,
    title: "OFFICE ALMIRAH",
    category: "office furniture",
    code: "TFSOA-080",
    imgSrc: chair80,
    size: "L1000 X W450 X H1800MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },

  // //               OFFICE LOCKER
  {
    id: 582,
    title: "OFFICE LOCKER",
    category: "office furniture",
    code: "TFSOL-077",
    imgSrc: chair077,
    size: "L900 X W500 X H1850MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  {
    id: 583,
    title: "OFFICE LOCKER",
    category: "office furniture",
    code: "TFSOL-078",
    imgSrc: chair078,
    size: "L350 X W500 X H1930MM",
    button: "Details",
    material: "mild welded steel after the conventional style and has gray paint coating of electrostatic zinc phosphate. This ensures that the paint coating is long lasting and the Almirah also has an oven baked paint finish. Practical and nice looking Almirah for any office space. Designed to be strong and long lasting this product is also spacious enough to store suitable office accessories when required"
  },
  // //                               office sofa
  {
    id: 584,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-079",
    imgSrc: chair079,
    size: "L1650 X W700 X H750MM",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  {
    id: 585,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-080",
    imgSrc: chair080,
    size: "L1800 X W700 X H720MM",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  {
    id: 586,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-081",
    imgSrc: chair81,
    size: "L685 X W750 X H635MM(SINGLE SEAT)",
    size2: "L1335 X W750 X H635MM(DOUBLE SEAT)",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  {
    id: 587,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-082",
    imgSrc: chair82,
    size: "L1135 X W750 X H635MM",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  {
    id: 588,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-083",
    imgSrc: chair83,
    size: "L1800 X W750 X H700MM(3 SEATER)",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  {
    id: 589,
    title: "OFFICE SOFA",
    category: "office furniture",
    code: "TFOSF-084",
    imgSrc: chair84,
    size: "L600 X W700 X H750MM(SINGLE SEATER)",
    size2: "L1350 X W700 X H750MM(DOUBLE SEATER)",
    button: "Details",
    material: "Kiln-dried imported Beech veneered engineered wood. Please refer to images for dimension details High quality Artificial Leather(PU) upholstery with soft and durable cushioning. Artificial Leather (PU) Leather can be selected from available options"
  },
  /////                        WORK STATION
  {
    id: 590,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-085",
    imgSrc: chair85,
    size: "L2500 X W650 X H1200MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 591,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-086",
    imgSrc: chair86,
    size: "L1200 X W1200 X H1050MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 592,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-087",
    imgSrc: chair87,
    size: "L3000 X W1650 X H1500MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 593,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-088",
    imgSrc: chair88,
    size: "L3000 X W3000 X H1050MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 594,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-089",
    imgSrc: chair89,
    size: "L3000 X W3000 X H1050MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  {
    id: 595,
    title: "WORK STATION",
    category: "office furniture",
    code: "TFWSO-090",
    imgSrc: chair90,
    size: "L2400 X W1200 X H1050MM",
    button: "Details",
    material: "superior quality melamine faced particle board with international standard density and load bearing capacity Same color edge banding done in latest Homag line Chrome finished mild steel Metal Inert Gas (MIG) welding for superior quality and durability"
  },
  // ///        FLUSH DOOR
  {
    id: 596,
    title: "FLUSH DOOR",
    category: "office furniture",
    code: "TFFDD-091",
    imgSrc: chair91,
    size: "L600~900 X H2080MM",
    button: "Details",
    material: "thin sheets of veneer over a core of wood. The veneer faces act as stressed- skin panels and tend to stabilize the door against warping. The face veneer being used is made of selected hard wood suitable for natural finish"
  },
  {
    id: 597,
    title: "FLUSH DOOR",
    category: "office furniture",
    code: "TFFDD-092",
    imgSrc: chair92,
    size: "L600~900 X H2080MM",
    button: "Details",
    material: "thin sheets of veneer over a core of wood. The veneer faces act as stressed- skin panels and tend to stabilize the door against warping. The face veneer being used is made of selected hard wood suitable for natural finish"
  },
  {
    id: 598,
    title: "FLUSH DOOR",
    category: "office furniture",
    code: "TFFDD-093",
    imgSrc: chair93,
    size: "L600~900 X H2080MM",
    button: "Details",
    material: "thin sheets of veneer over a core of wood. The veneer faces act as stressed- skin panels and tend to stabilize the door against warping. The face veneer being used is made of selected hard wood suitable for natural finish"
  },

  ///       SOLID WOODEN DOOR

  {
    id: 599,
    title: "SOLID WOODEN DOOR",
    category: "office furniture",
    code: "TFFDD-094",
    imgSrc: chair94,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 600,
    title: "SOLID WOODEN DOOR",
    category: "office furniture",
    code: "TFFDD-095",
    imgSrc: chair95,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 641,
    title: "SOLID WOODEN DOOR",
    category: "office furniture",
    code: "TFFDD-096",
    imgSrc: chair96,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },


  //                Home furniture

  //                            Bed
  {
    id: 5001,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-001",
    imgSrc: bed1,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5002,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-002",
    imgSrc: bed2,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5003,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-003",
    imgSrc: bed3,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5004,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-004",
    imgSrc: bed4,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5005,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-005",
    imgSrc: bed5,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5006,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-006", 
    imgSrc: bed6,    
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5007,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-007", 
    imgSrc: bed7,    
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5008,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-008", 
    imgSrc: bed8,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5009,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-009",
    imgSrc: bed9,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5010,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-010", 
    imgSrc: bed10,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5011,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-011", 
    imgSrc: bed11,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5012,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-012", 
    imgSrc: bed12,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },  
  { 
    id: 5013,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed13,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },

  // new

  { 
    id: 5014,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed14,  
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5015,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed15,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5016,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed16,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5017,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed17,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5018,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed18,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5019,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed19,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5020,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed20,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5021,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed21,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5022,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed22,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5023,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed23,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5024,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed24,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5025,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed25,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5026,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed26,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5027,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed27,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5028,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed28,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5029,
    title: "Bedroom Set",
    category: "home furniture",
    code: "TfBED-013", 
    imgSrc: bed29,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: ""
  },
  { 
    id: 5030,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 001 WD", 
    imgSrc: bed30,
    size: "L 2100 X W 1800 X H 1055",
    button: "Details",
    material: ""
  },
  { 
    id: 5031,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 002 WD", 
    imgSrc: bed31,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5032,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 003 WD", 
    imgSrc: bed32,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },

  { 
    id: 5033,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 004 WD", 
    imgSrc: bed33,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5034,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 005 WD", 
    imgSrc: bed34,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5035,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TEBD 006 WD", 
    imgSrc: bed35,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5036,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 001 WD", 
    imgSrc: bed36,
    size: "L 2100 X W 1800 X H 1050",
    button: "Details",
    material: ""
  },
  { 
    id: 5037,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 002 WD", 
    imgSrc: bed37,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5038,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 003 WD", 
    imgSrc: bed38,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5039,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 004 WD", 
    imgSrc: bed39,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5040,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 005 WD", 
    imgSrc: bed40,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5041,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 006 WD", 
    imgSrc: bed41,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5042,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 007 WD", 
    imgSrc: bed42,
    size: "L 2100 X W 1800 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 5043,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 008 WD", 
    imgSrc: bed43,
    size: "L 2100 X W 1800 X H 1050",
    button: "Details",
    material: ""
  },
  { 
    id: 5044,
    title: "Wooden Bed",
    category: "home furniture",
    code: "TKBD 009 WD", 
    imgSrc: bed44,
    size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },

  
  //       sofa

  {
    id: 5101,
    title: "Sofa 01",
    category: "home furniture",
    code: "Sofa-01", 
    imgSrc: sofa1,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5102,
    title: "Sofa 02",
    category: "home furniture",
    code: "Sofa-02", 
    imgSrc: sofa2,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5103,
    title: "Sofa 03",
    category: "home furniture",
    code: "Sofa-03", 
    imgSrc: sofa3,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5104,
    title: "Sofa 04",
    category: "home furniture",
    code: "Sofa-04", 
    imgSrc: sofa4,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5105,
    title: "Sofa 05",    
    category: "home furniture",    
    code: "Sofa-05",   
    imgSrc: sofa5, 
    size: "L600~1050 X H2080MM", 
    button: "Details", 
    material: "Mahogany & Veneered engineering wood" 
  },
  {
    id: 5106,
    title: "Sofa 06",
    category: "home furniture",
    code: "Sofa-06", 
    imgSrc: sofa6,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5107,
    title: "Sofa 07",
    category: "home furniture",
    code: "Sofa-07", 
    imgSrc: sofa7,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5108,
    title: "Sofa 08",
    category: "home furniture",
    code: "Sofa-08", 
    imgSrc: sofa8,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5109,
    title: "Sofa 09",
    category: "home furniture",
    code: "Sofa-09", 
    imgSrc: sofa9,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  {
    id: 5110,
    title: "Sofa 10",
    category: "home furniture",
    code: "Sofa-10", 
    imgSrc: sofa10,
    size: "L600~1050 X H2080MM",
    button: "Details",
    material: "Mahogany & Veneered engineering wood"
  },
  // {
  //   id: 5111,
  //   title: "Sofa 11",
  //   category: "home furniture",
  //   code: "Sofa-11", 
  //   imgSrc: sofa11,
  //   size: "L600~1050 X H2080MM",
  //   button: "Details",
  //   material: "Mahogany & Veneered engineering wood"
  // },

  {
    id: 5112,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-12", 
    imgSrc: sofa12,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5113,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-13", 
    imgSrc: sofa13,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  //
  {
    id: 5114,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-14", 
    imgSrc: sofa14,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5115,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-15", 
    imgSrc: sofa15,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5116,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-16", 
    imgSrc: sofa16,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5117,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-17", 
    imgSrc: sofa17,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5118,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-18", 
    imgSrc: sofa18,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5119,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-19", 
    imgSrc: sofa19,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5120,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-20", 
    imgSrc: sofa20,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5121,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-21", 
    imgSrc: sofa21,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5122,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-22", 
    imgSrc: sofa22,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  }, 
  {
    id: 5123,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-23", 
    imgSrc: sofa23,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  },
  {
    id: 5124,
    title: "Four Seater Sofa",
    category: "home furniture",
    code: "Sofa-24", 
    imgSrc: sofa24,
    size: "L 600 X W 1050 X H 2080MM",
    button: "Details",
    material: ""
  }, 


  //                     BED SIDE TABLE

  { 
    id: 5301,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 001 WD", 
    imgSrc: bedSideTable1,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5302,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 002 WD", 
    imgSrc: bedSideTable2,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5303,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 003 WD", 
    imgSrc: bedSideTable3,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5304,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 004 WD", 
    imgSrc: bedSideTable4,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5305,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 005 WD", 
    imgSrc: bedSideTable5,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5306,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 006 WD", 
    imgSrc: bedSideTable6,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5307,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 007 WD", 
    imgSrc: bedSideTable7,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5308,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 008 WD", 
    imgSrc: bedSideTable8,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },
  { 
    id: 5309,
    title: "Bed Side Table",
    category: "home furniture",
    code: "TKBD 009 WD", 
    imgSrc: bedSideTable9,
    // size: "L 2100 X W 1800 X H 1100",
    button: "Details",
    material: ""
  },

  //                      Cupboard

  { 
    id: 5401,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: cupboard1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5402,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: cupboard2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5403,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: cupboard3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5404,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: cupboard4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5405,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: cupboard5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5406,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: cupboard6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5407,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: cupboard7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5408,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: cupboard8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5409,
    title: "Cupboard",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: cupboard9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //                     Dressing table dressingTable1

  { 
    id: 5501,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: dressingTable1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5502,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: dressingTable2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5503,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: dressingTable3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5504,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: dressingTable4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5505,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: dressingTable5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5506,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: dressingTable6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5507,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: dressingTable7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5508,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: dressingTable8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5509,
    title: "Dressing Table",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: dressingTable9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

//               dinning table

{
  id: 5201,
  title: "Dinning 01",
  category: "home furniture",
  code: "Dinning-01", 
  imgSrc: dining1,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5202,
  title: "Dinning 02",
  category: "home furniture",
  code: "Dinning-02", 
  imgSrc: dining2,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5203,
  title: "Dinning 03",
  category: "home furniture",
  code: "Dinning-03", 
  imgSrc: dining3,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5204,
  title: "Dinning 04",
  category: "home furniture",
  code: "Dinning-04", 
  imgSrc: dining4,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5205,
  title: "Dinning 05",
  category: "home furniture",
  code: "Dinning-05", 
  imgSrc: dining5,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5206,
  title: "Dinning 06",
  category: "home furniture",
  code: "Dinning-06", 
  imgSrc: dining6,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5207,
  title: "Dinning 07",
  category: "home furniture",
  code: "Dinning-07", 
  imgSrc: dining7,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5208,
  title: "Dinning 08",
  category: "home furniture",
  code: "Dining-08", 
  imgSrc: dining8,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5209,
  title: "Dinning 9",
  category: "home furniture",
  code: "Dinning-9", 
  imgSrc: dining9,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5210,
  title: "Dinning 10",
  category: "home furniture",
  code: "Dinning-10", 
  imgSrc: dining10,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
// {
//   id: 5211,
//   title: "Dinning 11",
//   category: "home furniture",
//   code: "Dinning-11", 
//   imgSrc: dining11,
//   size: "L600~1050 X H2080MM",
//   button: "Details",
//   material: "Mahogany & Veneered engineering wood"
// },
{
  id: 5212,
  title: "Dinning 12",
  category: "home furniture",
  code: "Dinning-12", 
  imgSrc: dining12,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5213,
  title: "Dinning 13",
  category: "home furniture",
  code: "Dinning-13", 
  imgSrc: dining13,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5214,
  title: "Dinning 14",
  category: "home furniture",
  code: "Dinning-14", 
  imgSrc: dining14,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5215,
  title: "Dinning 15",
  category: "home furniture",
  code: "Dinning-15", 
  imgSrc: dining15,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5216,
  title: "Dinning 16",
  category: "home furniture",
  code: "Dinning-16", 
  imgSrc: dining16,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5217,
  title: "Dinning 17",
  category: "home furniture",
  code: "Dinning-17", 
  imgSrc: dining17,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5218,
  title: "Dinning 18",
  category: "home furniture",
  code: "Dinning-18", 
  imgSrc: dining18,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},
{
  id: 5219,
  title: "Dinning 19",
  category: "home furniture",
  code: "Dinning-19", 
  imgSrc: dining19,
  size: "L600~1050 X H2080MM",
  button: "Details",
  material: "Mahogany & Veneered engineering wood"
},


  //     dining wagon (diningwagon1)
  { 
    id: 5601,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5602,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5603,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5604,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5605,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5606,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5607,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5608,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5609,
    title: "Dining Wagon",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: diningwagon9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //     TV cabinet

  { 
    id: 5701,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: tvCabinet1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5702,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: tvCabinet2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5703,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: tvCabinet3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5704,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: tvCabinet4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5705,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: tvCabinet5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5706,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: tvCabinet6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5707,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: tvCabinet7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5708,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: tvCabinet8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5709,
    title: "TV Cabinet",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: tvCabinet9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //                  Book shelf   bookShelf1

  { 
    id: 5801,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: bookShelf1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5802,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: bookShelf2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5803,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: bookShelf3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5804,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: bookShelf4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5805,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: bookShelf5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5806,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: bookShelf6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5807,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: bookShelf7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5808,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: bookShelf8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5809,
    title: "Book Shelf",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: bookShelf9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },


  //                 Shoe rack

  { 
    id: 5901,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: shoeRack1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5902,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: shoeRack2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5903,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: shoeRack3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5904,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: shoeRack4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5905,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: shoeRack5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5906,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: shoeRack6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5907,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: shoeRack7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5908,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: shoeRack8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 5909,
    title: "Shoe Rack",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: shoeRack9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //             showcase
  { 
    id: 6001,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: showcase1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6002,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: showcase2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6003,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: showcase3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6004,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: showcase4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6005,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: showcase5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6006,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: showcase6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6007,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: showcase7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6008,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: showcase8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6009,
    title: "Show Case",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: showcase9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //            computer table  computerTable1
  { 
    id: 6101,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: computerTable1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6102,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: computerTable2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6103,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: computerTable3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6104,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: computerTable4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6105,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: computerTable5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6106,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: computerTable6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6107,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: computerTable7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6108,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: computerTable8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6109,
    title: "Reading Table",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: computerTable9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //          Tea Table  
  { 
    id: 6201,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: teaTable1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6202,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: teaTable2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6203,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: teaTable3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6204,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: teaTable4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6205,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: teaTable5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6206,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: teaTable6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6207,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: teaTable7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6208,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: teaTable8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6209,
    title: "Tea Table",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: teaTable9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //                    center table  centerTable1
  { 
    id: 6301,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: centerTable1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6302,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: centerTable2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6303,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: centerTable3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6304,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: centerTable4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6305,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: centerTable5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6306,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: centerTable6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6307,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: centerTable7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6308,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: centerTable8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6309,
    title: "Center Table",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: centerTable9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //             Rocking Chair     rockingChair1

  { 
    id: 6401,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 001 WD", 
    imgSrc: rockingChair1,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6402,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 002 WD", 
    imgSrc: rockingChair2,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6403,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 003 WD", 
    imgSrc: rockingChair3,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6404,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 004 WD", 
    imgSrc: rockingChair4,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6405,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 005 WD", 
    imgSrc: rockingChair5,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6406,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 006 WD", 
    imgSrc: rockingChair6,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6407,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 007 WD", 
    imgSrc: rockingChair7,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6408,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 008 WD", 
    imgSrc: rockingChair8,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },
  { 
    id: 6409,
    title: "Loop Easy Chair",
    category: "home furniture",
    code: "THCB 009 WD", 
    imgSrc: rockingChair9,
    size: "L 1650 X W 550 X H 1800",
    button: "Details",
    material: ""
  },

  //                      Director Table

  { 
    id: 6501,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 001 WD", 
    imgSrc: directorTable1,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6502,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 002 WD", 
    imgSrc: directorTable2,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6503,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 003 WD", 
    imgSrc: directorTable3,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6504,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 004 WD", 
    imgSrc: directorTable4,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },     
  { 
    id: 6505,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 005 WD", 
    imgSrc: directorTable5,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6506,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 006 WD", 
    imgSrc: directorTable6,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6507,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 007 WD", 
    imgSrc: directorTable7,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6508,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 008 WD", 
    imgSrc: directorTable8,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6509,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 009 WD", 
    imgSrc: directorTable9,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },
  { 
    id: 6511,
    title: "MD/Director Table",
    category: "office furniture",
    code: "THCB 011 WD", 
    imgSrc: directorTable11,
    size: "L 2100 X W 1650 X H 1200",
    button: "Details",
    material: ""
  },

  //                          lged(200)  
  
  {
    id: 251,
    title: "Low Bench",
    code: "40103/40034/40104",
    color: "#ba5645/#013220/#05014a",
    size: "Normal/ Extra Hieght/ Special Extra Height",
    category: "School Furniture",
    imgSrc: lged8,
    button: "Details"
  },
  {
    id: 252,
    title: "High Bench",
    code: "40100/40031/40101",
    color: "#ba5645/#013220/#05014a",
    size: "Normal/ Extra Hieght/ Special Extra Height",
    category: "lged",
    imgSrc: lged7,
    button: "Details"
  },
  {
    id: 253,
    title: "Bench Set",
    code: "40001/40002/40003",
    color: "#ba5645/#013220/#05014a",
    size: "Normal/ Extra Hieght/ Special Extra Height",
    category: "lged",
    imgSrc: lged1,
    button: "Details"
  },
  {
    id: 254,
    title: "Teacher Table",
    code: "40106/40107/40010/40004",
    color: "#ba5645/#05014a/orange/#000",
    size: "Normal/ Extra Hieght/ Special Extra Height",
    category: "School Furniture",
    imgSrc: lged10,
    button: "Details"
  },
  {
    id: 255,
    title: "Teacher Chair",
    code: "40108/40005/40006/40007",
    color: "#ba5645/#05014a/orange/#000",
    size: "550mm(L)X515mm(W)XH850mm(H)",
    category: "School Furniture",
    imgSrc: lged11,
    button: "Details"
  },
  
];

export default ProductItems; 
