import React, { Component } from "react";
// import "./PageTop.css";
import PageSlider from "../PageSlider/PageSlider";

class PageTop extends Component {
  render() {
    return (
      <>

  <PageSlider />
                
  </>
    );
  }
}

export default PageTop;
